<template>
  <div class="editor-desion-toolbar">
    <button
      ref="trigger"
      class="desion-menu-trigger"
      :class="triggerBtnClasses"
      @click="switchMenu"
    >
      <span class="trigger-icon">
        <v-icon size="40" color="white">
          mdi-plus
        </v-icon>
      </span>
      <span class="trigger-text">
        新增辨識規則
      </span>
    </button>
    <div
      v-show="menu.show"
      class="desion-menu"
      :style="menuStyle"
    >
      <template v-for="btn, i in desionBtns">
        <button
          class="btn-toolbar"
          @click="onBtnClick(i, $event)"
          :style="btn.style"
          :key="i"
        >
          {{btn.menuTitle}}
        </button>
        <v-divider
          :key="'d' + i"
          v-if="desionBtns.length !== (i + 1)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { getOffset } from '../helpers/utils';

export default {
  props: {
    plotId: {
      type: Number,
    },
    size: {
      type: Number,
      default: -1,
    },
  },
  computed: {
    plot() {
      const { plotId } = this;
      const plot = this.$store.state.plot.idMap[plotId];
      return plot;
    },
    plotDesions() {
      const { plot } = this;
      const desions = this.$store.state.desion.groupByParent;
      if (plot) {
        const plotId = plot.id;
        return desions[plotId] || [];
      }
      return [];
    },
    aDesions() {
      const { plot, plotDesions } = this;
      const desionDefs = this.$store.state.story.availableDesions;
      const aDesions = [];
      // console.log('reset plotDesions');
      // console.log(plot, plotDesions);
      if (plot) {
        const len = plotDesions.length;
        if (len > 0 && plotDesions[len - 1]) {
          const { group, type } = plotDesions[len - 1];
          const desionDef = desionDefs.find((def) => def.group === group && def.type === type);
          if (desionDef.last) {
            return [];
          }
        }
        for (const desionDef of desionDefs) {
          if (typeof desionDef.rule === 'function') {
            const pass = desionDef.rule(plot, plotDesions);
            if (pass) {
              aDesions.push(desionDef);
            }
          }
        }
      }
      return desionDefs;
    },
    desionBtns() {
      const desions = this.aDesions.map((desion) => {
        const {
          group,
          type,
          key,
          settings,
        } = desion;
        const { menuTitle } = settings;
        return {
          group,
          type,
          key,
          menuTitle,
        };
      });
      return desions;
    },
    triggerBtnClasses() {
      const cls = [];
      if (this.aDesions.length === 0) {
        cls.push('disabled');
      }
      return cls;
    },
    menuStyle() {
      const {
        left,
        top,
      } = this.menu;
      return {
        left: `${left}px`,
        top: `${top}px`,
      };
    },
  },
  watch: {
    aDesions() {
      if (this.aDesions.length === 0) {
        this.menu.show = false;
      }
    },
  },
  data() {
    return {
      menu: {
        show: false,
        left: 0,
        top: 0,
      },
      // 預設 size
      iconSize: 48,
      padding: 12,
    };
  },
  mounted() {
    if (this.size > 0) {
      this.iconSize = this.size;
    }
    this.padding = this.iconSize / 4;
  },
  methods: {
    switchMenu() {
      // 暫時先只用 trigger 按鈕去觸發新增 desion 的功能
      // this.onBtnClick(0);
      // return;
      // TODO 之後要把選單完成
      // eslint-disable-next-line no-unreachable
      console.log('switch-menu');
      const { menu, aDesions } = this;
      if (!menu.show && aDesions.length > 0) {
        const {
          width,
          height,
          left,
          top,
        } = getOffset(this.$refs.trigger);
        Object.assign(menu, {
          top: 0 - height,
          left: width + 10,
        });
        menu.show = true;
      } else {
        menu.show = false;
      }
    },
    onBtnClick(i, $event) {
      // eslint-disable-next-line no-shadow
      console.log('$event', $event);
      const btn = this.aDesions[i];
      this.$emit('btn-click', {
        index: i,
        btn,
        $event,
      });
      this.$emit('click', $event);
    },
  },
};
</script>

<style lang="scss" scoped>
  .editor-desion-toolbar{
    .desion-menu-trigger{
      border: 1px solid #517EFE;
      &:hover{
        opacity: .8;
      }
      &:active{
        opacity: .6;
      }
      &.disabled{
        border: 1px solid #3c5eb9;
        .trigger-text{
          color: rgb(211, 211, 211);
        }
      }
    }
    .trigger-icon{
      background-color: #517EFE;
      margin-bottom: -2px;
    }
    .trigger-text{
      padding: 4px 12px 4px 12px;
      letter-spacing: 2px;
      color: #517EFE;
    }
    .desion-menu-trigger, .trigger-icon, .trigger-text{
      display: flex;
      align-items: center;
    }
    .desion-menu{
      display: flex;
      width: fit-content;
      position: relative;
      z-index: 10;
      box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
      background-color: white;
      border-radius: 8px;
    }
  }
  .btn-toolbar{
    padding: 12px;
    outline: none;
    color: #517EFE;
  }
  .btn-toolbar{
    &:hover{
      background-color: #f7f7f7;
    }
    &:active{
      background-color: #ececec;
    }
  }
</style>
