/* eslint-disable no-plusplus */
/* eslint-disable import/prefer-default-export */
export function delay(t) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, t);
  });
}

/**
 * @param {String} key (範例："action:pa-news/change-img")
 */
export function parseKeyString(key) {
  if (key) {
    const i1 = key.indexOf(':');
    // 要處理的類型
    let type = '';
    if (i1 >= 0) {
      type = key.substr(0, i1);
    }
    if (type === 'action') {
      const [group, actionType] = key.substr(i1 + 1).split('/');
      return {
        type,
        group,
        actionType,
      };
    }
    if (type === 'desion') {
      const [group, desionType] = key.substr(i1 + 1).split('/');
      return {
        type,
        group,
        desionType,
      };
    }
  }
  return null;
}

/**
 * @param {HTMLElement} el
 */
export function getOffset(el) {
  const rect = el.getBoundingClientRect();
  return {
    height: rect.height,
    width: rect.width,
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
  };
}

// 測試用
// 過一陣子確定沒問題之後砍掉
// let t = 0;
// eslint-disable-next-line no-underscore-dangle
function _insertSortedList(list, item, cp, start, end) {
  const len = end - start;
  if (len === 0) {
    list.splice(start, 0, item);
    return start;
  }
  if (len === 1) {
    const c = cp(list[start], item);
    if (c < 0) {
      list.splice(start + 1, 0, item);
      return start + 1;
    }
    list.splice(start, 0, item);
    return start;
  }

  const lenH = start + Math.floor(len / 2);
  const c = cp(list[lenH], item);
  if (c === 0) {
    list.splice(lenH, 0, item);
    return lenH;
  }
  if (c < 0) {
    return _insertSortedList(list, item, cp, lenH + 1, end);
  }
  return _insertSortedList(list, item, cp, start, lenH);
}
/**
 * 將資料插入已排序的陣列之中
 * 方法類似2元搜尋 , O(logN)
 *
 * @param {Array<*>} list 必須是已排序狀態
 * @param {*} item
 * @param {Function} compareCB 比較兩個 item 的大小,
 * < 0 => item 放右邊, > 0 => item 放左邊, = 0 item 也會放右邊(stable)
 * @returns {Number} 插入的 item 的位置
 */
export function insertSortedList(list, item, compareCB) {
  if (list.length === 0) {
    list.push(item);
    return 0;
  }
  if (list.length === 1) {
    const p = compareCB(list[0], item);
    if (p < 0) {
      list.push(item);
      return 1;
    }
    list.unshift(item);
    return 0;
  }
  return _insertSortedList(list, item, compareCB, 0, list.length);
}

// eslint-disable-next-line no-underscore-dangle
function _searchSortedList(list, item, cp, start, end) {
  const len = end - start;
  if (len <= 3) {
    for (let i = 0; i < len; i++) {
      const ni = i + start;
      const c = cp(list[ni], item);
      if (c === 0) {
        return ni;
      }
    }
    return -1;
  }
  const lenH = start + Math.floor(len / 2);
  const c = cp(list[lenH], item);
  // console.log(list[lenH], 'compare', item, c);
  if (c === 0) {
    return lenH;
  }
  if (c < 0) {
    // console.log(lenH + 1, end);
    return _searchSortedList(list, item, cp, lenH + 1, end);
  }
  // console.log(start, lenH);
  return _searchSortedList(list, item, cp, start, lenH);
}

/**
 * 搜尋並回傳對應資料的索引值
 *
 * @param {*} list
 * @param {*} item
 * @param {*} compareCB
 */
export function searchSortedList(list, item, compareCB) {
  return _searchSortedList(list, item, compareCB, 0, list.length);
}

/**
 * 回傳一個 26 進位並且以 A 為起點表示的字串
 *
 * 例：1:A, 26:Z, 27:AA
 * @param {Number} num
 * @returns {String}
 */
export function enNum(num) {
  if (Number.isInteger(num)) {
    const s = 'A'.charCodeAt(0);
    let v;
    let txt = '';
    do {
      v = num % 26;
      // eslint-disable-next-line no-param-reassign
      num = Math.floor(num / 26);
      txt = String.fromCharCode(s + v) + txt;
    } while (num > 0);
    return txt;
  }
  return '';
}
// 測試用
// console.log('insertSortedList', insertSortedList);
// window.insertSortedList = insertSortedList;

/**
 * 檢查物件名稱是否有不應該出現的字元
 *
 * @param {*} data
 * @returns {String}
 */
export function checkItemName(data) {
  const name = data.name || '';
  return name.match(/[#_]/);
}

/**
 * 假如物件名稱出現不應該出現的字元，直接自動取代
 *
 * @param {*} data
 * @returns {String}
 */
export function filterItemName(data) {
  const name = data.name || '';
  return name.replace(/[#_]/g, '').trim();
}

export function empty() {}

const genMap = {
  '': 0,
};
export function idGen(prefix = '') {
  if (!genMap[prefix]) {
    genMap[prefix] = 0;
  }
  const id = genMap[prefix]++;
  return `${prefix}_${id}`;
}
