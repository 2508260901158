/* eslint-disable no-use-before-define */
import { empty } from './utils';
import * as nseSettings from './nse-settings';

let loginCB = empty;

/**
 * 換頁可能要統一清楚事件，所以在這邊把要清除的存在一起
 */
const onMsgCallbacks = [];

export function getStorageToken() {
  // eslint-disable-next-line no-shadow
  const token = window.localStorage.getItem('nse-token');
  const expired = window.localStorage.getItem('nse-token-expired');
  if (!token) {
    return null;
  }
  return {
    token,
    expired: expired && new Date(expired),
  };
}
/**
 * @param {String} nToken
 * @param {Number} expired Date.getTime()
 */
export function setStorageToken(nToken, expired) {
  if (nToken) {
    window.localStorage.setItem('nse-token', nToken);
    const sExpire = expired ? new Date(expired).toISOString() : '';
    window.localStorage.setItem('nse-token-expired', sExpire);
  }
}

export function clearStorageToken() {
  window.localStorage.setItem('nse-token', '');
  window.localStorage.setItem('nse-token-expired', '');
}

/**
 * 基本上應該只在 APP.vue 中使用一次
 *
 * 依據 localStorage 的 token 判斷是否登入 & 取得個人的相關資訊
 */
export async function initPageLoginStatus(cb) {
  // eslint-disable-next-line global-require
  const $v = require('./event-bus').default;
  const tokenInfo = getStorageToken();
  if (tokenInfo) {
    const { token, expired } = tokenInfo;
    const now = new Date();
    loginCB = cb;
    if (!expired || (now >= expired)) {
      nseSettings.setToken(token);
      $v.$emit('token-setup');
      loginCB();
    }
  }
  return false;
}

/**
 * 如果頁面有使用 initPageLoginStatus
 *
 * 則在 onBeforeDestory 時應該執行此 function
 */
export function onDestoryPage() {
  loginCB = empty;
  for (const cb of onMsgCallbacks) {
    window.removeEventListener('message', cb);
  }
}

export function checkStoryPermission() {

}

export function openLoginWindow(cb) {
  // 避免一次開一堆視窗，把之前開的關掉
  if (openLoginWindow.child) {
    openLoginWindow.child.close();
  }
  const prefixKey = 'voiss-login:';
  if (cb) {
    loginCB = cb;
  }
  let child;
  /**
   * @param {MessageEvent} ev
   */
  const func = (ev) => {
    console.log(ev.source === child);
    if (typeof ev.data === 'string' && ev.source === child) {
      const { data } = ev;
      console.log('on event', data);
      // 檢查 message 的開頭是否為 {{prefixKey}}
      if (data.substr(0, prefixKey.length) === prefixKey) {
        // 登入畫面傳回來的 event
        const vEvent = JSON.parse(data.substr(prefixKey.length));
        console.log('on login', vEvent);
        const { type } = vEvent;
        if (type === 'page-ready') {
          console.log('on login page ready');
        } else if (type === 'login') {
          // eslint-disable-next-line no-unused-vars
          const { loginType, token, expired } = vEvent;
          setStorageToken(token, expired);
          nseSettings.setToken(token);
          loginCB(vEvent);
        }
      }
    }
  };
  window.addEventListener('message', func, false);
  child = window.open('https://auth.voiss.cc/login#/?onlyVoiss=1', '', 'toolbar=0,status=0,width=600,height=530');
  openLoginWindow.child = child;
  const checkChild = () => {
    if (child.closed) {
      console.log('on login page close');
      openLoginWindow.child = null;
      clearInterval(checkChildTimer);
    }
  };
  const checkChildTimer = setInterval(checkChild, 2000);
  onMsgCallbacks.push(func);
  return func;
}
