<template>
  <div class="editor-float-toolbar">
    <template v-for="btn, i in aActions">
      <button
        class="btn-toolbar"
        @click="onBtnClick(i, $event)"
        :style="btn.style"
        :key="i"
      >
        <v-icon
          :color="btn.color"
          :size="btn.size || iconSize"
          v-text="btn.icon"
        >
        </v-icon>
      </button>
    </template>
  </div>
</template>

<script>
import { colorPack } from '../helpers/action-settings';
/**
 * 參考 store/story.availableActions 的 settings
 * 以下對應的表示是在特定位置(place=xxx)時
 */
const settingsKeyMap = {
  preAction: {
    icon: 'toolbarIcon',
    color: 'toolbarColor',
    bgColor: 'toolbarBG',
  },
  reply: {
    icon: 'ssDialogIcon',
    color: 'ssDialogColor',
    bgColor: 'ssDialogBG',
  },
};
export default {
  props: {
    size: {
      type: Number,
      default: -1,
    },
    place: {
      type: String,
      default: 'preAction',
    },
  },
  computed: {
    availableActions() {
      return this.$store.state.story.availableActions;
    },
    sColor() {
      return colorPack[this.place] || colorPack.preAction;
    },
    sKey() {
      return settingsKeyMap[this.place] || settingsKeyMap.preAction;
    },
    btnStyle() {
      const {
        sColor,
        padding,
      } = this;
      const borderColor = sColor.primary;
      return {
        padding: `${padding}px`,
        borderColor,
      };
    },
    aActions() {
      const { sKey, btnStyle } = this;
      const list = this.availableActions.map((aInfo) => {
        const { settings } = aInfo;
        const color = settings[sKey.color];
        const bgColor = settings[sKey.bgColor];
        const icon = settings[sKey.icon];
        return {
          key: aInfo.key,
          icon,
          color,
          style: {
            color,
            backgroundColor: bgColor,
            ...btnStyle,
          },
        };
      });
      return list;
    },
  },
  data() {
    return {
      // 預設 size
      iconSize: 48,
      padding: 12,
    };
  },
  mounted() {
    if (this.size > 0) {
      this.iconSize = this.size;
    }
    this.padding = this.iconSize / 4;
  },
  methods: {
    onBtnClick(i, $event) {
      // eslint-disable-next-line no-shadow
      const btn = this.aActions[i];
      this.$emit('btn-click', {
        index: i,
        btn,
        $event,
      });
      this.$emit('click', $event);
    },
  },
};
</script>

<style lang="scss" scoped>
  .btn-toolbar{
    padding: 12px;
    border: 1px solid #517EFE;
    outline: none;
  }
  .btn-toolbar{
    &:hover{
      opacity: .7;
    }
    &:active{
      opacity: .5;
    }
  }
</style>
