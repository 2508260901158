/* eslint-disable prefer-const */
import { throttle } from 'lodash';
import bus from '../helpers/event-bus';

/**
 * 只綁定在 App.vue 上
 * 會自動更新 store.screen
 *
 * 其他元件最好是透過接收 resize 事件來更新畫面
 */

export const mixins = {
  mounted() {
    let updateScreen = this.updateScreen.bind(this);
    updateScreen();
    window.addEventListener('resize', updateScreen);
    this.updateScreen = updateScreen;
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateScreen);
  },
  methods: {
    updateScreen: throttle(async function () {
      await this.$store.dispatch('updateScreen');
      bus.$emit('resize', this.$store.state.screen);
    }, 200),
  },
};
export default mixins;
