<template>
  <div class="action-title-icon" :style="outterStyle">
    <v-icon v-text="icon" size="40" :color="color"/>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
    },
    size: {
      type: Number,
    },
    color: {
      type: String,
    },
    background: {
      type: String,
    },
    borderColor: {
      type: String,
    },
  },
  computed: {
    outterStyle() {
      return {
        backgroundColor: this.background,
        borderColor: this.borderColor,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
$icon-size: 60px;
.action-title-icon{
  display: flex;
  justify-content: center;
  border: 2px solid #517EFE;
  border-radius: 30px;
  height: $icon-size;
  width: $icon-size;
}
</style>
