<template>
  <div class="story-editor-scenes">
    <div class="scene-header">
      <!-- Scene Tabs -->
      <!-- TODO 超過畫面時的情況有待處理 -->
      <div class="scenes-tab-wrapper">
        <button
          v-for="scene, i in scenes"
          :key="i"
          class="scene-tab"
          @click="btnChangeScene(i, $event)"
          @contextmenu.prevent="btnContextmenu($event, scene)"
        >
          <div class="d-inline-flex align-center" style="outline: none">
            <button class="d-inline-flex" @click.stop="btnEditSceneName(i, $event)">
              <v-icon
                class="mr-2"
                color="#517EFE"
                size="24"
              >
                mdi-square-edit-outline
              </v-icon>
            </button>
            <EditText
              :value="scene.name"
              s-type="scene-tab"
              :double="true"
              :edit.sync="edit[i]"
              @change="updateSceneName(i, $event)"
            >
            </EditText>
            <v-icon
              class="ml-2"
              color="#517EFE"
              size="18"
              @click.stop="btnDeleteScene(i, $event)"
              v-if="i !== current"
            >
              mdi-close
            </v-icon>
          </div>
        </button>

        <button
          class="scene-tab"
          @click="btnCreateScene()"
          @contextmenu.prevent=""
        >
          <div class="d-inline-flex align-center">
            新增場景
            <v-icon class="ml-2" color="#517EFE" size="18">
              mdi-plus-thick
            </v-icon>
          </div>
        </button>
        <v-menu
          v-model="menu.show"
          :position-x="menu.x"
          :position-y="menu.y"
          absolute
          offset-y
        >
          <v-list>
            <v-list-item
              v-for="(item) in menu.items"
              :key="item.id"
              @click="onMenuClick(item.key, menu.sceneId)"
              :disabled="item.disabled"
            >
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-spacer></v-spacer>
    </div>
    <StorySceneEditor />
    <ConfirmDialog ref="confirm"/>
  </div>
</template>

<script>
import { idGen } from '../helpers/utils';
import { createEmptyScene } from '../helpers/data-create';
import StorySceneEditor from './StorySceneEditor.vue';
import ConfirmDialog from './ConfirmDialog.vue';

export default {
  components: {
    StorySceneEditor,
    ConfirmDialog,
  },
  watch: {
    scenes() {
      console.log('update edit array');
      const edit = new Array(this.scenes.length);
      for (let i = 0; i < this.scenes.length; i += 1) {
        edit[i] = false;
      }
      this.edit = edit;
    },
  },
  computed: {
    scenes() {
      return this.$store.state.scene.scenes;
    },
    current() {
      return this.$store.state.scene.cPage;
    },
    plots() {
      return this.$store.state.plot.plots;
    },
  },
  data() {
    return {
      menu: {
        show: false,
        x: 0,
        y: 0,
        sceneId: 0,
        items: [
          {
            id: idGen(),
            key: 'copy',
            title: '複製場景',
            disabled: true,
          },
          {
            id: idGen(),
            key: 'insert',
            title: '插入場景',
            disabled: true,
          },
        ],
      },
      edit: [],
    };
  },
  methods: {
    deleteConfirm(msg) {
      return this.$refs.confirm.confirm(msg, {
        posBtnText: '刪除',
        posBtnColor: 'error',
      });
    },
    /**
     * 切換編輯狀態
     */
    btnEditSceneName(i) {
      // console.log('btnEditSceneName');
      const edit = Array.from(this.edit);
      for (let j = 0; j < this.edit.length; j += 1) {
        edit[j] = false;
      }
      edit[i] = true;
      this.edit = edit;
    },
    async btnCreateScene() {
      await this.$store.dispatch('scene/createScene', createEmptyScene());
    },
    async btnDeleteScene(i) {
      if (await this.deleteConfirm('確定要刪除該場景嗎?')) {
        await this.$store.dispatch('scene/deleteScene', this.scenes[i].id);
      }
    },
    async updateSceneName(i, name) {
      const scene = {
        ...this.scenes[i],
        name,
      };
      await this.$store.dispatch('scene/updateScene', scene);
    },
    btnContextmenu(e, scene) {
      this.menu.show = false;
      this.menu.x = e.clientX;
      this.menu.y = e.clientY;
      this.menu.sceneId = scene.id;
      this.$nextTick(() => {
        this.menu.show = true;
      });
    },
    onMenuClick(key, sceneId) {
      console.log(key, sceneId);
    },
    btnChangeScene(i) {
      const { scenes } = this;
      if (scenes[i]) {
        // 換頁
        this.$store.commit('scene/CHANGE_PAGE', i);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
button{
  outline: none;
}
.scene-header{
  display: flex;
  background-color: #517EFE;
  height: 70px;
  .scenes-tab-wrapper{
    display: flex;
    align-self: end;
  }
  .scene-header-right{
    display: flex;
    align-self: center;
  }
  .scene-tab{
    color: #517EFE;
    background-color: white;
    margin-right: 8px;
    padding: 8px 12px 4px 12px;
    border-radius: 8px 8px 0 0;
    user-select: none;
  }
  .scene-tab:hover{
    background-color: #F6F6F6;
    cursor: pointer;
  }
}
</style>
