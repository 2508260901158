<template>
  <div class="voiss-editor-brand">
    <a class="d-flex align-center" @click="onClick">
      <v-img
        alt="VOISS Logo"
        class="shrink mr-2"
        contain
        src="~/../assets/logo-toolbar.png"
        transition="scale-transition"
        width="70"
      />

      <span class="site-title ml-2">
        互動腳本編輯器
      </span>
    </a>
  </div>
</template>
<script>
export default {
  props: [
    'to',
    'href',
  ],
  methods: {
    onClick() {
      if (this.to) {
        this.$router.push(this.to);
        return;
      }
      if (this.href) {
        window.location.href = this.href;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.voiss-editor-brand{
  cursor: pointer;
  user-select: none;
  outline: none;
  &:hover{
    opacity: 0.8;
  }
  &:active{
    opacity: 0.6;
  }
  .site-title{
    color: #6C6C6C;
    font-size: 19px;
    letter-spacing: 2px;
  }
}
</style>
