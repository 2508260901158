<template>
  <v-dialog
    v-model="show"
    persistent
    min-width="200"
    max-width="300"
  >
    <v-card class="confirm-wrap" @contextmenu.prevent="">
      <v-card-title>
        <div v-html="contentHTML"></div>
      </v-card-title>

      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :color="s.posBtnColor"
          @click="btnConfirm"
        >
          {{s.posBtnText}}
        </v-btn>
        <v-btn
          :color="s.negBtnColor"
          @click="btnCancel"
        >
          {{s.negBtnText}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { pick } from 'lodash';

const defaultOpts = {
  posBtnColor: 'primary',
  posBtnText: '確定',
  negBtnColor: 'secondary',
  negBtnText: '取消',
};
const optKeys = Object.keys(defaultOpts);

export default {
  props: ['value', 'content'],
  computed: {
    show: {
      set(v) {
        // 如果有綁定 value
        if (this.value !== undefined) {
          this.$emit('input', v);
          if (this.value !== v) {
            this.$emit('change', v);
          }
        } else {
          this.selfShow = v;
        }
      },
      get() {
        if (this.value !== undefined) {
          return this.value;
        }
        return this.selfShow;
      },
    },
  },
  watch: {
    show() {
      if (this.show && this.content) {
        this.contentHTML = this.content;
      }
      // 關閉時清除
      if (!this.show) {
        this.contentHTML = '';
      }
    },
  },
  data() {
    return {
      selfShow: false,
      contentHTML: '',
      s: {
        ...defaultOpts,
      },
    };
  },
  methods: {
    /**
     * 給上層透過 $refs 呼叫的 func
     */
    confirm(content, opts = {}) {
      return new Promise((resolve, reject) => {
        if (this.show) {
          reject(new Error('confirm dialog 目前正在使用中'));
        } else {
          Object.assign(this.s, pick(opts, optKeys));
          this.show = true;
          this.contentHTML = content;
          let regEvents = [];
          const offAll = () => {
            // 清理所有事件
            for (const [name, handler] of regEvents) {
              this.$off(name, handler);
            }
            regEvents = [];
          };
          const onConfirm = () => {
            this.show = false;
            offAll();
            resolve(true);
          };
          const onCancel = () => {
            this.show = false;
            offAll();
            resolve(false);
          };
          regEvents = [
            ['confirm', onConfirm],
            ['cancel', onCancel],
          ];
          for (const [name, handler] of regEvents) {
            this.$once(name, handler);
          }
        }
      });
    },
    btnConfirm() {
      this.$emit('select', true);
      this.$emit('confirm');
    },
    btnCancel() {
      this.$emit('select', false);
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-wrap{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.loading-content{
  text-align: center;
}
.loading-message{
  font-size: 14px;
  margin-top: 8px;
  color: grey;
}
</style>
