<template>
  <!-- 功能：顯示文字並在點擊後變成可編輯的文字 -->
  <div class="c-edit-text-container">
    <input
      v-if="editMode"
      ref="input"
      v-model="tmpValue"
      v-click-outside="onClickOutside"
      :type="type"
      class="c-edit-text"
      :style="inputStyle"
      :placeholder="placeholder"
      @blur="onClickOutside"
      @keyup="onKeyup"
    />
    <span
      v-if="!editMode"
      class="c-edit-text"
      :class="{ 'c-edit-text-empty': !value }"
      :style="textStyle"
      @click="onClick()"
      @dblclick="onDbclick()"
      v-text="value || placeholder"
      :title="value"
    ></span>
  </div>
</template>

<script>
/* eslint-disable quote-props */

/**
 * 兩個模式都會套用的 style
 */
const styleMap = {};
/**
 * text(span) 模式時才會套用的 style
 */
const textStyleMap = {};
const placeholderStyleMap = {};
/**
 * input 模式時才會套用的 style
 */
const inputStyleMap = {
  'empty': {
  },
  'base-1': {
    'border': 'none',
    'border-radius': '8px',
    'text-align': 'center',
    'padding': '4px',
    'min-width': '50px',
    'max-width': '100px',
    'width': '100%',
  },
};

// plot-name
styleMap['plot-name'] = {
  'color': '#517EFE',
  'text-align': 'left',
  'min-width': '50px',
};
// scene-tab
styleMap['scene-tab'] = {
  'color': '#517EFE',
  'min-width': '30px',
  'max-width': '120px',
};
textStyleMap['scene-tab'] = {
  'white-space': 'nowrap',
  'overflow': 'hidden',
  'text-overflow': 'ellipsis',
};
inputStyleMap['scene-tab'] = {
  'width': '100%',
};
export default {
  props: {
    value: {
      validator(s) {
        return s == null || typeof s === 'string';
      },
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    sType: {
      type: String,
      default: 'normal',
    },
    placeholder: {
      type: String,
      default: '',
    },
    /**
     * 若該屬性為 true 則要雙擊才會觸發編輯
     */
    double: {
      type: Boolean,
      default: false,
    },
    /**
     * 若該屬性為 true 則必須要由父元件修改 edit 屬性才有辦法編輯
     *
     * 會無視 "double" 屬性
     */
    manual: {
      type: Boolean,
      default: false,
    },
    outsideCancel: {
      type: Boolean,
      default: false,
    },
    /**
     * 編輯的狀態，用於切換操作的元件
     * true => <input>
     * false => <span>
     */
    edit: {
      // type: Boolean, 不指定 boolean ，用 undefined 表示沒有綁定變數
      default: undefined,
    },
  },
  data() {
    return {
      // 沒有綁定資料的時候，用這個欄位來保存編輯狀態
      sEdit: false,
      tmpValue: '',
    };
  },
  watch: {
    edit(v, old) {
      // console.log('edit', v, old);
      if (v && v !== old) {
        this.tmpValue = this.value;
        this.$nextTick(() => {
          this.$refs.input.click();
          this.$refs.input.focus();
        });
      }
    },
  },
  computed: {
    editMode: {
      set(v, old) {
        if (this.edit === undefined) {
          // 如果沒有綁定 edit
          this.sEdit = v;
        } else {
          this.$emit('update:edit', v);
        }
      },
      get() {
        const v = this.edit === undefined ? this.sEdit : this.edit;
        return v;
      },
    },
    inputStyle() {
      const style1 = styleMap[this.sType] || {};
      const style2 = inputStyleMap[this.sType] || {};
      return { ...style1, ...style2 };
    },
    textStyle() {
      const style1 = styleMap[this.sType] || {};
      const style2 = textStyleMap[this.sType] || {};
      let style3 = {};
      if (!this.value) {
        style3 = placeholderStyleMap[this.sType] || {
          color: '#999',
        };
      }
      return { ...style1, ...style2, ...style3 };
    },
    placeholderStyle() {
      let style1;
      if (this.editMode) {
        style1 = this.inputStyle;
      } else {
        style1 = this.textStyle;
      }
      const style2 = placeholderStyleMap[this.sType] || {
        color: '#999',
      };
      return { ...style1, ...style2 };
    },
  },
  methods: {
    onClickOutside() {
      if (this.editMode) {
        if (this.outsideCancel) {
          this.editCancel();
        } else {
          this.editDone();
        }
      }
    },
    startEdit() {
      if (!this.editMode) {
        this.editMode = true;
        this.tmpValue = this.value;
        this.$nextTick(() => {
          if (this.editMode) {
            this.$refs.input.focus();
          }
        });
      }
    },
    onDbclick() {
      if (!this.manual && this.double) {
        this.startEdit();
      }
    },
    onClick() {
      if (!this.manual && !this.double) {
        this.startEdit();
      }
    },
    onKeyup($e) {
      this.$emit('input', this.tmpValue);
      if ($e.which === 13) {
        // ENTER
        this.editDone();
      }
      console.log($e, this.tmpValue);
    },
    editDone() {
      this.editMode = false;
      this.$emit('change', this.tmpValue);
      this.$emit('update', this.tmpValue);
    },
    editCancel() {
      this.editMode = false;
    },
  },
};
</script>

<style scoped>
.c-edit-text-container{
  display: inline-block;
  overflow: hidden;
}
.c-edit-text {
  /* display: inline-block; */
  outline: none;
  width: 100%;
}
.c-edit-text-empty {
  opacity: 0.8;
}
</style>
