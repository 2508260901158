<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="600"
    v-model="show"
  >
    <div class="branch-sentences-manager">
      <div class="sentences-manage">
        <div class="sentences-manage-side">
          <button @click="close">
            <v-icon color="#FFD600" size="50">mdi-arrow-left-bold-box</v-icon>
          </button>
        </div>
        <div class="sentences-manage-main">
          <div class="header-block">
            <div class="header-title">
              例句管理
            </div>
            <input
              class="header-branch-common"
              type="text"
              placeholder="輸入備註"
              v-model="comment"
              @input="updateBranchComment()"
            />
          </div>
          <div class="sentences-area">
            <!-- 新增 sentence 用 -->
            <div class="sentence-edit">
              <input
                class="sentence-input"
                type="text"
                v-model="tmpSentence"
                placeholder="輸入後按ENTER新增"
                @keyup.enter="addFirstSentence(tmpSentence);tmpSentence = '';"
              />
            </div>
            <!-- 編輯 sentence 用 -->
            <div
              class="sentence-edit"
              v-for="sentence,i in sentences"
              :key="i"
            >
              <input
                class="sentence-input"
                type="text"
                :value="sentence"
                @change="changeSentence(i, $event.target.value)"
              />
              <button class="btn-delete-sentence" @click="deleteSentence(i)">
                <v-icon>mdi-close</v-icon>
              </button>
              <!-- <button class="btn-edit-sentence">
                <v-icon color="#FFD600" size="30">mdi-arrow-right-bold-box</v-icon>
              </button> -->
            </div>
          </div>
        </div>
      </div>
      <div class="replies-manage">
        <div class="header-block">
          <div class="header-title">
            選項回應
          </div>
        </div>
        <div class="replies-area">
          <ActionView
            v-for="action,i in reply"
            :key="i"
            place="reply"
            :action="action"
            @update:action="onUpdateReply(i, $event)"
            @delete="onDeleteReply(i, $event)"
          />
          <StoryEditorFloatToolbar
            :size="24"
            place="reply"
            @btn-click="createReply"
          />
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { debounce } from 'lodash';
import { createAction } from '../helpers/data-create';
import { parseKeyString } from '../helpers/utils';
import StoryEditorFloatToolbar from './StoryEditorFloatToolbar.vue';
import ActionView from './ActionView.vue';

export default {
  components: {
    StoryEditorFloatToolbar,
    ActionView,
  },
  props: {
    value: {
      type: Boolean,
    },
    branchId: {
      type: Number,
    },
  },
  computed: {
    show: {
      set(v) {
        this.$emit('input', v);
        if (this.value !== v) {
          this.$emit('change', v);
        }
      },
      get() {
        return this.value;
      },
    },
    branch() {
      return this.$store.state.branch.idMap[this.branchId];
    },
    branchData() {
      const { branch } = this;
      if (branch) {
        return branch.data || {};
      }
      return {};
    },
    replyActions() {
      return this.$store.state.rep_action.groupByParent[this.branchId] || [];
    },
  },
  watch: {
    value(show) {
      // 每次關閉時檢查
      if (!show) {
        if (this.tmpSentence) {
          this.addSentence(this.tmpSentence);
          this.tmpSentence = '';
        }
      }
    },
    branchData: {
      handler() {
        const { branchData } = this;
        this.sentences = branchData.sentences || [];
        this.comment = branchData.comment || '';
      },
      immediate: true,
    },
    replyActions() {
      this.reply = Array.from(this.replyActions);
    },
  },
  data() {
    return {
      tmpSentence: '',
      comment: '',
      sentences: [],
      reply: [],
    };
  },
  created() {
    this.updateBranchComment = debounce(this.updateBranchComment, 200);
    this.changeSentence = debounce(this.changeSentence, 200);
  },
  mounted() {
    const { branch } = this;
    if (branch) {
      const data = branch.data || {};
      this.sentences = data.sentences || [];
      this.comment = data.comment || '';
    }
    this.reply = Array.from(this.replyActions);
  },
  methods: {
    updateBranchComment() {
      this.updateBranchData();
    },
    addFirstSentence(sentence) {
      // eslint-disable-next-line no-param-reassign
      sentence = (sentence || '').trim();
      // 拒絕空白的句子
      if (!sentence) { return; }
      const { sentences } = this;
      sentences.unshift(sentence);
      this.updateBranchData();
    },
    addSentence(sentence) {
      // eslint-disable-next-line no-param-reassign
      sentence = (sentence || '').trim();
      // 拒絕空白的句子
      if (!sentence) { return; }
      const { sentences } = this;
      sentences.push(sentence);
      this.updateBranchData();
    },
    changeSentence(i, sentence) {
      const { sentences } = this;
      sentences[i] = sentence;
      this.updateBranchData();
    },
    deleteSentence(i) {
      const { sentences } = this;
      sentences.splice(i, 1);
      this.updateBranchData();
    },
    updateBranchData() {
      const {
        sentences,
        comment,
      } = this;
      const data = {
        ...this.branchData,
        sentences,
        comment,
      };
      const id = this.branchId;
      this.$store.dispatch('branch/updateBranchData', { id, data });
    },
    // eslint-disable-next-line no-unused-vars
    async createReply({ index, btn, $event }) {
      const { branchId } = this;
      const opts = parseKeyString(btn.key);
      if (opts) {
        if (opts.type === 'action') {
          const action = createAction(opts.group, opts.actionType);
          action.branchId = branchId;
          await this.$store.dispatch('rep_action/createRepAction', action);
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    onUpdateReply(i, action) {},
    // eslint-disable-next-line no-unused-vars
    onDeleteReply(i, action) {},
    close() {
      this.$emit('input', false);
      this.$emit('change', false);
    },
  },
};
</script>

<style scoped lang="scss">
// 跟左邊的間隔
$indent: 60px;

.branch-sentences-manager{
  background-color: white;
  padding: 16px;
  .sentences-manage{
    display: flex;
    flex-direction: row;
  }
  .sentences-manage-side{
    width: 60px;
  }
  .sentences-manage-main{
    flex-grow: 1;
  }
  .replies-manage{
    margin-top: 16px;
    margin-left: $indent;
  }
  .header-block{
    display: flex;
    align-items: center;
    height: 50px;
    padding: 6px;
    border: 2px solid #FFA800;
    border-radius: 8px;
    background-color: #FFF6D8;
  }
  .header-title{
    color: #FF9900;
    font-size: 16px;
    margin-left: 4px;
    margin-right: 4px;
    letter-spacing: 3px;
  }
  .header-branch-common{
    outline: none;
    flex-grow: 1;
    padding: 2px 8px 2px 8px;
    background-color: white;
    border: 1.5px solid #FF9900;
    letter-spacing: 2px;
  }
  .replies-area, .sentences-area{
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 16px;
  }
  // 每一個例句的編輯框
  .sentence-edit{
    display: flex;
    align-items: center;
    border: 1px solid #FF9900;
    background-color: white;
    border-radius: 4px;
    height: 38px;
    padding: 4px;
    gap: 8px;
  }
  .sentence-input{
    outline: none;
    flex-grow: 1;
    letter-spacing: 1px;
    font-weight: 200;
    margin-left: 8px;
  }
}
</style>
