/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { ParameterError } from './errors';
import axios from './nse-axios';

// const debug = require('debug')('nse-api');
const debug = console.log.bind(console, 'nse-api:');

const noDataCheck = process.env.VUE_APP_NO_API_DATA_CHECK === 'true';
const noApiTest = process.env.VUE_APP_NO_API_TEST === 'true';
/*
  nse-api.js，用於處理該網頁大部分的基本功能，包含故事各部分資料的上傳下載等等
 */

/**
 * api 驗證用的 token
 * (登入後透過 setToken 設定)
 */

/**
 * @returns {null|Object} null 表示未登入
 */
export async function getSelfInfo() {
  debug('getSelfInfo');
  const res = await axios.get('/nse/user');
  return res.data;
}

// ---------- #story ----------
/**
 * @param {*} storyId
 * @param {*} storyData
 * @returns
 */
export async function publishStory(storyId) {
  debug('publishStory', { storyId });
  if (noApiTest) {
    return {
      // TODO
    };
  }
  const res = await axios.post(`/nse/story/${storyId}/publish`);
  return res.data;
}

export async function createStoryByTemplate(template, name, storyId) {
  if (noApiTest) {
    return {
      // TODO
    };
  }
  const data = {
    template,
    name,
  };
  const res = await axios.post(`/nse/story/${storyId}`, data);
  return res.data;
}

/**
 * @param {*} storyId
 * @returns {Promise<Boolean>}
 */
export async function checkStoryExist(storyId) {
  const res = await axios.get(`/nse/story/${storyId}/existing`);
  return res.data;
}

export async function callStoryTest(storyId, datas) {
  const res = await axios.post(`/nse/story/${storyId}/test`, datas);
  return res.data;
}

export async function loadStoryInfo(storyId) {
  if (!noDataCheck) {
    if (!storyId) {
      throw new ParameterError('缺少必要的參數 storyId');
    }
  }
  debug('loadStoryInfo', { storyId });
  if (noApiTest) {
    return {};
  }
  return {};
  // TODO 等之後要抓 story_settings 的時候要再改
  // eslint-disable-next-line no-unreachable
  // const res = await axios.get('/XXXXXXXXXXX');
}

/**
 * getOwnedStories
 *
 * @param {String} storyId
 * @returns {StoryData}
 */
export async function getOwnedStories() {
  debug('getOwnedStories');
  if (noApiTest) {
    return {};
  }
  const res = await axios.get('/nse/stories/');
  return res.data;
}
/**
 * getStoryDatas
 *
 * @param {String} storyId
 * @returns {StoryData}
 */
export async function getStoryDatas(storyId) {
  debug('getStoryDatas', { storyId });
  if (noApiTest) {
    return {};
  }
  const res = await axios.get(`/nse/story/${storyId}`);
  return res.data;
}

export async function updateStory(storyId, updatePatch) {
  debug('updatePlot', { storyId, ...updatePatch });
  if (noApiTest) {
    return updatePatch;
  }
  const res = await axios.get('/XXXXXXXXXXX');
  return res.data;
}

// ---------- #scene ----------
export async function createScene(storyId, sceneData) {
  debug('createScene', { ...sceneData });
  if (noApiTest) {
    return sceneData;
  }
  const res = await axios.post('/nse/scene', {
    storyId,
    ...sceneData,
  });
  return res.data;
}

export async function updateScene(sceneData) {
  debug('updateScene', { ...sceneData });
  if (noApiTest) {
    return sceneData;
  }
  const res = await axios.patch(`/nse/scene/${sceneData.id}`, sceneData);
  return res.data;
}

export async function deleteScene(sceneId) {
  debug('deleteScene', { sceneId });
  if (noApiTest) {
    return;
  }
  const res = await axios.delete(`/nse/scene/${sceneId}`);
  return res.data;
}

// ---------- #plot ----------
export async function createPlot(plotData) {
  debug('createPlot', { ...plotData });
  if (noApiTest) {
    return plotData;
  }
  const res = await axios.post('/nse/plot', plotData);
  return res.data;
}

export async function updatePlot(plotData) {
  debug('updatePlot', { ...plotData });
  if (noApiTest) {
    return plotData;
  }
  const res = await axios.patch(`/nse/plot/${plotData.id}`, plotData);
  return res.data;
}

export async function deletePlot(plotId) {
  debug('deletePlot', { plotId });
  if (noApiTest) {
    return;
  }
  const res = await axios.delete(`/nse/plot/${plotId}`);
  return res.data;
}

// ---------- #pre_action ----------
/**
 * @param {String} plotId
 * @param {Action} action
 * @returns {Action}
 */
export async function createPreAction(action) {
  debug('createPreAction', action);
  const { plotId } = action;
  if (!noDataCheck) {
    if (!plotId) {
      throw new ParameterError('缺少必要的參數 action.plotId');
    }
  }
  if (noApiTest) {
    return action;
  }
  const res = await axios.post('/nse/pre_action', action);
  return res.data;
}

/**
 * @param {String} plotId
 * @param {Action} action
 * @returns {Action}
 */
export async function updatePreAction(action) {
  debug('updatePreAction', action);
  const { id } = action;
  if (!noDataCheck) {
    if (!id) {
      throw new ParameterError('缺少必要的參數 action.id');
    }
  }
  if (noApiTest) {
    return action;
  }
  const res = await axios.patch(`/nse/pre_action/${id}`, action);
  return res.data;
}

/**
 * @param {String} plotId
 * @param {String} actionId
 * @returns {void}
 */
export async function deletePreAction(actionId) {
  debug('deletePreAction', { actionId });
  if (!noDataCheck) {
    if (!actionId) {
      throw new ParameterError('缺少必要的參數 actionId');
    }
  }
  if (noApiTest) {
    return;
  }
  const res = await axios.delete(`/nse/pre_action/${actionId}`);
  return res.data;
}

// ---------- #rep_action ----------
/**
 * @param {String} plotId
 * @param {Action} action
 * @returns {Action}
 */
export async function createRepAction(action) {
  debug('createRepAction', action);
  const { branchId } = action;
  if (!noDataCheck) {
    if (!branchId) {
      throw new ParameterError('缺少必要的參數 action.branchId');
    }
  }
  if (noApiTest) {
    return action;
  }
  const res = await axios.post('/nse/rep_action', action);
  return res.data;
}

/**
 * @param {String} plotId
 * @param {Action} action
 * @returns {Action}
 */
export async function updateRepAction(action) {
  debug('updateRepAction', action);
  const { id } = action;
  if (!noDataCheck) {
    if (!id) {
      throw new ParameterError('缺少必要的參數 action.id');
    }
  }
  if (noApiTest) {
    return action;
  }
  const res = await axios.patch(`/nse/rep_action/${id}`, action);
  return res.data;
}

/**
 * @param {String} plotId
 * @param {String} actionId
 * @returns {void}
 */
export async function deleteRepAction(actionId) {
  debug('deleteRepAction', { actionId });
  if (!noDataCheck) {
    if (!actionId) {
      throw new ParameterError('缺少必要的參數 actionId');
    }
  }
  if (noApiTest) {
    return;
  }
  const res = await axios.delete(`/nse/rep_action/${actionId}`);
  return res.data;
}

/**
 * @param {String} plotId
 * @param {Desion} desion
 * @returns {Desion}
 */
export async function createDesion(desion) {
  if (!noDataCheck) {
    if (!desion) {
      throw new ParameterError('缺少必要的參數 desion');
    }
  }
  debug('createDesion', { ...desion });
  if (noApiTest) {
    return desion;
  }
  const res = await axios.post('/nse/desion', desion);
  return res.data;
}

/**
 * @param {Desion} desion
 * @returns {Desion}
 */
export async function updateDesion(desion) {
  const { id } = desion;
  if (!noDataCheck) {
    if (!id) {
      throw new ParameterError('缺少必要的參數 desion.id');
    }
  }
  debug('updateDesion', { ...desion });
  if (noApiTest) {
    return desion;
  }
  const res = await axios.patch(`/nse/desion/${id}`, desion);
  return res.data;
}

/**
 * @param {String} desionId
 * @returns {void}
 */
export async function deleteDesion(desionId) {
  if (!noDataCheck) {
    if (!desionId) {
      throw new ParameterError('缺少必要的參數 plotId 或 desion.id');
    }
  }
  debug('deleteDesion', { desionId });
  if (noApiTest) {
    return;
  }
  const res = await axios.delete(`/nse/desion/${desionId}`);
  return res.data;
}

// ---------- #branch ----------
/**
 * @param {Branch} branch
 * @returns {Branch}
 */
export async function createBranch(branch) {
  if (!noDataCheck) {
    if (!branch) {
      throw new ParameterError('缺少必要的參數 branch');
    }
  }
  debug('updateBranch', { ...branch });
  if (noApiTest) {
    return branch;
  }
  const res = await axios.post('/nse/branch', branch);
  return res.data;
}

/**
 * @param {Branch} branch
 * @returns {Branch}
 */
export async function updateBranch(branch) {
  const { id } = branch;
  if (!noDataCheck) {
    if (!id) {
      throw new ParameterError('缺少必要的參數 branch.id');
    }
  }
  debug('updateBranch', { ...branch });
  if (noApiTest) {
    return branch;
  }
  const res = await axios.patch(`/nse/branch/${id}`, branch);
  return res.data;
}

/**
 * @param {String} branchId
 * @returns {void}
 */
export async function deleteBranch(branchId) {
  if (!noDataCheck) {
    if (!branchId) {
      throw new ParameterError('缺少必要的參數 branchId');
    }
  }
  debug('deleteBranch', { branchId });
  if (noApiTest) {
    return;
  }
  const res = await axios.delete(`/nse/branch/${branchId}`);
  return res.data;
}

// ---------- #通用 ----------
/**
 *
 * @param {'scene'|'plot'|} type
 * @param {*} orderMap
 */
export async function updateOrder(type, orderMap) {
  //
}
