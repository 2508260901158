/* eslint-disable no-param-reassign */
/**
 * @typedef {import('vuex').Module} VuexModule
 */

import Vue from 'vue';
import { filterItemName } from '../helpers/utils';
import * as nseApi from '../helpers/nse-api';
import {
  createNseEventHandler,
} from '../helpers/store-tools';

/**
 * @type {VuexModule}
 */
export const m = {
  namespaced: true,
  state: () => ({
    // 存所有的 scene
    scenes: [],
    idMap: {},
    // 表示當前是處於第幾頁
    cPage: -1,
  }),
  getters: {
    scene(state) {
      return state.scenes[state.cPage];
    },
  },
  mutations: {
    SETUP(state, scenes) {
      if (!scenes) { return; }
      const { idMap } = state;
      state.cPage = scenes.length > 0 ? 0 : -1;
      for (const scene of scenes) {
        Vue.set(idMap, scene.id, scene);
      }
      state.scenes = scenes;
    },
    CHANGE_PAGE(state, i) {
      state.cPage = i;
    },
    MOVE_PAGE(state, mv) {
      state.cPage += mv;
    },
    DELETE_BY_ID(state, id) {
      const { scenes, idMap } = state;
      if (idMap[id]) {
        const i = scenes.findIndex((scene) => scene.id === id);
        m.mutations.DELETE_BY_INDEX(state, i);
      }
    },
    DELETE_BY_INDEX(state, i) {
      const { scenes, cPage, idMap } = state;
      const scene = scenes[i];
      if (scene) {
        scenes.splice(i, 1);
        Vue.delete(idMap, scene.id);
        if (!scenes[cPage]) {
          state.cPage = scenes.length - 1;
        }
      }
    },
    UPDATE(state, scene) {
      const { scenes, idMap } = state;
      Vue.set(idMap, scene.id, scene);
      const i = scenes.findIndex((s) => scene.id === s.id);
      if (i < 0) {
        scenes.push(scene);
      } else {
        scenes.splice(i, 1, scene);
      }
    },
    APPEND(state, scene) {
      const { scenes, idMap } = state;
      if (idMap[scene.id]) {
        throw new Error(`scene id 重複, id=${scene.id}`);
      }
      Vue.set(idMap, scene.id, scene);
      scenes.push(scene);
    },
    INSERT(state, { scene, i }) {
      const { scenes, idMap } = state;
      if (Number.isInteger(i)) {
        if (idMap[scene.id]) {
          throw new Error(`scene id 重複, id=${scene.id}`);
        }
        Vue.set(idMap, scene.id, scene);
        scenes.splice(i, 0, scene);
      }
    },
    SET(state, { scene, i }) {
      const { scenes, idMap } = state;
      if (scenes[i]) {
        Vue.delete(idMap, scenes[i].id);
      }
      if (idMap[scene.id]) {
        throw new Error(`scene id 重複, id=${scene.id}`);
      }
      Vue.set(idMap, scene.id, scene);
      scenes.splice(i, 1, scene);
    },
  },
  actions: {
    ...createNseEventHandler({
      createFuncName: 'createScene',
      updateFuncName: 'updateScene',
      deleteFuncName: 'deleteScene',
    }),
    async createScene({ rootState, commit }, scene) {
      const { storyId } = rootState.story;
      filterItemName(scene);
      const nScene = await nseApi.createScene(storyId, scene);
      commit('APPEND', nScene);
      return nScene;
    },
    async cloneScenes(ctx, payload) {
      const {
        rootState,
        state,
        dispatch,
      } = ctx;
      const { storyId } = rootState.story;
      const {
        id,
      } = payload;
      const scene = state.idMap[id];
      if (id) {
        const nScene = await nseApi.createScene(storyId, scene);
        await dispatch('plot/clonePlots', {
          parentId: id,
          to: nScene.id,
        });
      } else {
        throw new Error(`scene ${id} not found`);
      }
    },
    async updateScene({ commit }, scene) {
      filterItemName(scene);
      commit('UPDATE', scene);
      const nScene = await nseApi.updateScene(scene);
      return nScene;
    },
    async deleteScene({ commit }, sceneId) {
      await nseApi.deleteScene(sceneId);
      commit('DELETE_BY_ID', sceneId);
    },
  },
};

export default m;
