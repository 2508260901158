import Vue from 'vue';
import LongPress from 'vue-directive-long-press';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import EditText from './components/EditText.vue';

Vue.config.productionTip = false;

Vue.directive('long-press', LongPress);
Vue.component('EditText', EditText);

const vue = new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
