<template>
  <div class="editor-page">
    <StoryEditorScenes
      v-if="storyId && accInfo"
    />
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import StoryEditorScenes from '../components/StoryEditorScenes.vue';
import StoryEditorPlot from '../components/StoryEditorPlot.vue';
import ActionView from '../components/ActionView.vue';
import * as nseApi from '../helpers/nse-api';
import { queryEvents } from '../helpers/nse-event';

export default {
  name: 'StoryEditor',
  layout: 'editor',
  // layout 會根據 requireLogin 做出反應
  // 當 requireLogin 為 true 且用戶尚未登入時
  // layout 將不會顯示當前頁面內容，而是要求用戶登入
  requireLogin: true,
  components: {
    StoryEditorScenes,
    StoryEditorPlot,
    ActionView,
  },
  computed: {
    accInfo() {
      return this.$store.state.accInfo;
    },
    storyId() {
      return this.$store.state.story.storyId;
    },
  },
  watch: {
    accInfo: {
      handler() {
        // 切換帳號 or 登入後要重新載入故事並檢查
        this.init();
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    if (this.stopQueryEvent) {
      this.stopQueryEvent();
    }
  },
  methods: {
    async init() {
      if (this.stopQueryEvent) {
        this.stopQueryEvent();
        this.stopQueryEvent = null;
      }
      const { storyId } = this.$route.params;
      await this.initStoryDatas();
    },
    async initStoryDatas() {
      const { storyId } = this.$route.params;
      let time = new Date();
      let done = false;
      try {
        const exist = await nseApi.checkStoryExist(storyId);
        if (!exist) {
          this.$store.commit('SET_AUTH_ERROR', {
            code: 'STORY_NOT_FOUND',
            message: `故事 ${storyId} 不存在`,
            data: {
              storyId,
            },
          });
          return;
        }
        const storyDatas = await this.$store.dispatch('story/loadAllDatas', storyId);
        time = new Date(storyDatas.loadTime);
        done = true;
      } catch (e) {
        console.error(e);
        this.$store.commit('SET_AUTH_ERROR', {
          code: 'STORY_LOAD_FAILED',
          message: `故事 ${storyId} 載入失敗`,
          data: {
            storyId,
          },
        });
        // this.$router.push(`/error/?msg=${window.encodeURIComponent(msg)}`);
      }
      // 自動循環取得編輯器事件
      if (done) {
        const ctx = queryEvents(storyId, ({ sessionId }, data) => {
          const { events, wait } = data;
          // 顯示每次等待的時間
          // console.log('query events', wait);
          if (events.length > 0) {
            this.$store.dispatch('handleStoryEvents', events);
          }
        });
        this.stopQueryEvent = ctx.stopLoop;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
