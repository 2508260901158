<template>
  <div class="editor-action" :class="outerClass">
    <div
      v-if="action"
      :is="view"
      :action="action"
      :color="color"
      :parent="parent"
      :lock="lock"
      @update-data="onUpdateData(action.id, $event)"
      @delete="onDelete"
      @drag-start="onDragStart"
      @drag-end="onDragEnd"
      :style="style"
    ></div>
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
/* eslint-disable quote-props */
import { debounce } from 'lodash';
import ActionPanewsChangeImg from './ActionPanewsChangeImg.vue';
import ActionPanewsSetVar from './ActionPanewsSetVar.vue';
import ActionPanewsPlay from './ActionPanewsPlay.vue';
import { colorPack } from '../helpers/action-settings';

const viewMap = {
  'pa-news': {
    'change-img': [
      'ActionPanewsChangeImg',
      ActionPanewsChangeImg,
    ],
    'set-var': [
      'ActionPanewsSetVar',
      ActionPanewsSetVar,
    ],
    'play': [
      'ActionPanewsPlay',
      ActionPanewsPlay,
    ],
  },
};

const components = {};
for (const group in viewMap) {
  for (const type in viewMap[group]) {
    const [name, view] = viewMap[group][type];
    components[name] = view;
  }
}

export default {
  // 根據 viewMap 自動產生
  components,
  props: {
    action: {
      type: Object,
    },
    parent: {
      type: Object,
    },
    place: {
      type: String,
      default: 'preAction',
    },
    maxWidth: {
      type: String,
      default: '370',
    },
    /**
     * 若該屬性為 true ，則此 action 不應該被編輯
     */
    lock: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      let pack = colorPack[this.place];
      if (!pack) {
        pack = colorPack.preAction;
      }
      return pack;
    },
    /**
     * 回傳對應的 component
     */
    view() {
      if (this.action) {
        const { group, type } = this.action;
        const view = viewMap[group][type];
        if (view && view[1]) {
          return view[1];
        }
      }
      console.warn('view not found');
      return 'div';
    },
    style() {
      let { maxWidth } = this;
      if (maxWidth) {
        if (maxWidth.match(/^\d+$/)) {
          maxWidth += 'px';
        }
      }
      return {
        maxWidth,
      };
    },
    outerClass() {
      if (this.drag) {
        return ['action-dragging'];
      }
      return [];
    },
  },
  data() {
    return {
      edit: null,
      updatedAt: null,
      wait: false,
      drag: false,
    };
  },
  created() {
    this._updateData = debounce(this.updateData, 200);
  },
  async beforeDestroy() {
    // 如果原本更新還沒上傳完，先等待上傳
    if (this.edit) {
      const { id, data, place } = this.edit;
      await this.updateData(id, data, place);
    }
  },
  methods: {
    async onUpdateData(id, data, place = null) {
      place = place || this.place;
      this.edit = { id, data, place };
      await this._updateData(id, data, place);
      this.edit = null;
    },
    async updateData(id, data, place) {
      let nAction;
      if (place === 'preAction') {
        nAction = await this.$store.dispatch('pre_action/updatePreAction', { id, data });
      } else {
        nAction = await this.$store.dispatch('rep_action/updateRepAction', { id, data });
      }
      this.$emit('update:action', nAction);
    },
    async onDelete() {
      const { place, action } = this;
      if (place === 'preAction') {
        await this.$store.dispatch('pre_action/deletePreAction', action.id);
      } else {
        await this.$store.dispatch('rep_action/deleteRepAction', action.id);
      }
      this.$emit('delete');
    },
    onDragStart(e) {
      const { action } = this;
      console.log('onDragStart', e);
      this.$emit('drag-start', { e, action });
    },
    onDragEnd(e) {
      const { action } = this;
      console.log('onDragEnd', e);
      this.$emit('drag-end', { e, action });
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-action>*{
  user-select: none;
}
</style>
