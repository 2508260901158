<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="600"
    v-model="show"
    :persistent="loading"
  >
    <v-card class="dialog-content">
      <v-card-text>
        <div class="dialog-header">
          創建故事
        </div>
        <v-form ref="form">
          <!-- name -->
          <v-text-field
            label="故事名稱"
            v-model="tmpName"
            :rules="nameRules"
            :disabled="loading"
          ></v-text-field>
          <!-- storyId -->
          <v-text-field
            label="故事ID"
            v-model="tmpStoryIdParts"
            :rules="idRules"
            :disabled="loading"
          >
            <template v-slot:prepend>
              <v-chip>
                {{prefix}}
              </v-chip>
            </template>
          </v-text-field>
          <!-- tempalte -->
          <v-select
            :items="templates"
            v-model="template"
            label="樣板"
            item-text="title"
            item-value="value"
            disabled
          ></v-select>
        </v-form>
        <v-alert type="error" v-if="errMsg">
          {{errMsg}}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="btnSubmit"
          color="primary"
          :loading="loading"
        >
          建立
        </v-btn>
        <v-btn @click="btnCancel">
          取消
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { debounce, keyBy } from 'lodash';
import { createAction } from '../helpers/data-create';
import { delay } from '../helpers/utils';
import * as nseApi from '../helpers/nse-api';

export default {
  props: {
    value: {
      type: Boolean,
    },
  },
  computed: {
    show: {
      set(v) {
        this.$emit('input', v);
        if (this.value !== v) {
          this.$emit('change', v);
        }
        if (!v) {
          // 每次關閉時清除
          this.errMsg = '';
        }
      },
      get() {
        return this.value;
      },
    },
    prefix() {
      const { accInfo } = this.$store.state;
      if (accInfo && accInfo.nse) {
        return accInfo.nse.story_prefix || 'PAPP_';
      }
      return 'PAPP_';
    },
  },
  data() {
    return {
      // prefix: 'PAPP_',
      templates: [
        {
          title: '親子天下故事基本樣板 - 1',
          value: 'pa-news-1',
        },
      ],
      template: 'pa-news-1',
      tmpName: '',
      tmpStoryIdParts: '',
      loading: false,

      idRules: [
        (storyId) => (storyId.length < 1 ? 'ID不可留空' : true),
      ],
      nameRules: [
        (name) => (name.length < 3 ? '故事名稱至少需要 3 個字' : true),
        (name) => (name.length > 64 ? '故事名稱不可超過 64 個字' : true),
      ],
      errMsg: '',
    };
  },
  mounted() {
  },
  methods: {
    async btnSubmit() {
      this.submit();
    },
    async submit() {
      if (!this.loading) {
        this.loading = true;
        try {
          const { prefix, tmpName, tmpStoryIdParts } = this;
          const storyId = prefix + tmpStoryIdParts;
          await delay(1000);
          await nseApi.createStoryByTemplate('pa-news-1', tmpName, storyId);
          this.$emit('create-story');
          this.close();
        } catch (e) {
          if (e.response) {
            const { data } = e.response;
            this.errMsg = data.message;
          }
          console.error(e);
        }
        this.loading = false;
      }
    },
    btnCancel() {
      if (!this.loading) {
        this.close();
      }
    },
    close() {
      this.$emit('input', false);
      this.$emit('change', false);
    },
  },
};
</script>

<style scoped lang="scss">
.dialog-header{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 6px;
  cursor: pointer;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #FFA800;
  background-color: #FFF6D8;
  color: #FFA800;
}
.dialog-content{
  padding: 16px;
}
</style>
