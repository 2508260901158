<template>
  <div class="action-panews-play" :style="outterStyle">
    <div class="title-side">
      <CircleActionIcon
        icon="mdi-microphone"
        :color="color.primary"
        background="white"
        :border-color="color.primary"
      />
      <CircleActionIcon
        icon="mdi-lead-pencil"
        :color="color.primary"
        background="white"
        :border-color="color.primary"
      />
    </div>
    <div class="content-side">
    <div class="d-flex top-area">
      <v-spacer></v-spacer>
      <button
        v-if="!lock"
        class="btn-delete"
        :style="deleteBtnStyle"
        @click="btnDelete"
      >
        &times;
      </button>
    </div>
      <div class="edit-field-wrap">
        <input
          type="text"
          class="edit-field edit-audio"
          v-model="tempAudio"
          :placeholder="audioPlaceholder"
          @change="updateData"
          :disabled="lock"
        />
      </div>
      <div class="edit-field-wrap">
        <textarea
          class="edit-field edit-text"
          v-model="tempText"
          :placeholder="textPlaceholder"
          @change="updateData"
          :disabled="lock"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import EditText from './EditText.vue';
import CircleActionIcon from './CircleActionIcon.vue';

export default {
  components: {
    CircleActionIcon,
    // eslint-disable-next-line vue/no-unused-components
    EditText,
  },
  props: {
    action: {
      type: Object,
    },
    parent: {
      type: Object,
    },
    color: {
      type: Object,
    },
    lock: {
      type: Boolean,
    },
  },
  created() {
    // this.updateData = debounce(this.updateData, 200);
  },
  data() {
    return {
      audioPlaceholder: '(輸入URL)',
      textPlaceholder: '請輸入內容',
      tempAudio: '',
      tempText: '',
    };
  },
  computed: {
    actionData() {
      const { action } = this;
      if (action && action.data) {
        return action.data;
      }
      return {};
    },
    outterStyle() {
      return {
        borderColor: this.color.primary,
        backgroundColor: this.color.bgColor,
      };
    },
    titleStyle() {
      return {
        color: this.color.primary,
      };
    },
    commonBtnStyle() {
      return {
        borderColor: this.color.primary,
      };
    },
    deleteBtnStyle() {
      return {
        backgroundColor: this.color.primary,
      };
    },
    imgKeys() {
      return Object.keys(this.img);
    },
  },
  watch: {
    action() {
      this.initAction();
    },
  },
  mounted() {
    this.initAction();
  },
  methods: {
    initAction() {
      // console.log('init action');
      const { actionData } = this;
      this.tempText = actionData.text;
      this.tempAudio = actionData.audio;
    },
    updateData() {
      const { tempText, tempAudio } = this;
      this.update({
        text: tempText,
        audio: tempAudio,
      });
    },
    // 儲存
    update(data = {}) {
      const nData = {
        audio: data.audio == null ? data.audio : (data.audio || ''),
        text: data.text == null ? data.text : (data.text || ''),
      };
      this.$emit('update-data', nData);
    },
    // 刪除
    btnDelete() {
      this.$emit('delete');
    },
  },
};
</script>

<style scoped lang="scss">
$icon-size: 60px;
$border-width: 2px;
.w-100 {
  width: 100%;
}
.action-panews-play {
  // box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  color: #000000;
  height: auto;
  width: 100%;
  background-color: #F0F8FF;
  border: $border-width solid #517EFE;
  border-radius: $icon-size / 2;
  .edit-field-wrap{
    padding: 8px;
    min-height: $icon-size;
    .edit-field{
      width: 90%;
      outline: none;
      background-color: #FFF;
      border: 1px solid #ACA7A7;
      border-radius: 6.33px;
    }
    .edit-field::placeholder{
      color: rgba(150, 150, 150, 0.5);
    }
    .edit-audio{
      padding: 8px;
      height: 40px;
    }
    .edit-text{
      margin-top: -8px; // 切齊 icon
      padding: 8px;
      height: 150px !important;
      resize: none;
    }
  }

  .top-area{
    position: relative;
    right: 3px;
    top: -5px;
    height: 0px;
  }
  .btn-delete{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    width: 16px;
    height: 16px;
    color: #FFF;
    background-color: #517EFE;
    &:hover{
      opacity: 0.7;
    }
  }
  .title-side{
    flex-basis: 0;
    width: $icon-size;
    // background-color: #da1818;
    margin-top: 0 - $border-width;
    margin-left: 0 - $border-width;
    margin-bottom: 0 - $border-width;
  }
  .content-side{
    width: calc(100% - #{$icon-size + 10px});
    // background-color: #1187ee;
  }
  .title-side, .content-side{
    display: flex;
    flex-direction: column;
    // 各欄位的行間距
    & > *:not(:last-child){
      margin-bottom: 8px;
    }
  }

}
</style>
