<template>
  <v-dialog
    v-model="loading"
    persistent
    width="200"
  >
    <v-card class="loading-wrap" @contextmenu.prevent="">
      <div class="loading-content">
        <v-progress-circular indeterminate color="#517EFE"/>
        <div class="loading-message">
          {{message}}
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['value', 'message'],
  computed: {
    loading: {
      set(v) {
        this.$emit('input', v);
        if (this.value !== v) {
          this.$emit('change', v);
        }
      },
      get() {
        return this.value;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-wrap{
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;
}
.loading-content{
  text-align: center;
}
.loading-message{
  font-size: 14px;
  margin-top: 8px;
  color: grey;
}
</style>
