/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
/**
 * @typedef {import('vuex').Module} VuexModule
 */

import {
  createNseDataHandleMutations,
  createNseEventHandler,
  createNseCloneAction,
  createNseBasicActions,
} from '../helpers/store-tools';
import { filterItemName } from '../helpers/utils';
import * as nseApi from '../helpers/nse-api';

/**
   * @type {VuexModule}
   */
export const m = {
  namespaced: true,
  state: () => ({
    // 存所有的 desion
    desions: [],
    idMap: {},
    // group by parent
    groupByParent: {},
  }),
  mutations: {
    // 建立操作 desion 的相關 mutations
    ...createNseDataHandleMutations({
      name: 'desion',
      stateKey: 'desions',
      getId: (data) => data.id,
      getParentId: (data) => data.plotId,
    }),
  },
  actions: {
    ...createNseEventHandler({
      createFuncName: 'createDesion',
      updateFuncName: 'updateDesion',
      deleteFuncName: 'deleteDesion',
    }),
    ...createNseBasicActions({
      name: 'desion',
      createFunc: 'createDesion',
      updateFunc: 'updateDesion',
      updateDataFunc: 'updateDesionData',
      deleteFunc: 'deleteDesion',

      handleOrderBy: 'plotId',
    }),
    async createDesionWithBranches({ dispatch }, { desion, branches }) {
      const nDesion = await dispatch('createDesion', desion);
      const bBranches = [];
      let od = 0;
      // console.log('branches', branches);
      for (const branch of branches) {
        branch.desionId = nDesion.id;
        branch.order = branch.order || od;
        await dispatch('branch/createBranch', branch, { root: true });
        // bBranches.push(await nseApi.createBranch(branch));
        // commit('branch/APPEND', branch, { root: true });
        od += 1;
      }
      return {
        desion: nDesion,
        branches: bBranches,
      };
    },
    cloneDesions: createNseCloneAction({
      name: 'desion',
      parentKey: 'plotId',
      cloneKey: 'createDesion',
      cloneChildren: [
        'branch/cloneBranches',
      ],
      beforeUpload(desion, nDesion) {
        const key = `${nDesion.group}/${nDesion.type}`;
        if (key === 'common/closest-s') {
          nDesion.data.nLinks = nDesion.data.links;
          nDesion.data.links = [];
        }
      },
      async afterChildrenUpload(desion, nDesion, childsMap) {
        const nBranches = childsMap['branch/cloneBranches'];
        const { id } = nDesion;
        const key = `${nDesion.group}/${nDesion.type}`;
        if (key === 'common/closest-s') {
          const map = {};
          for (const branch of nBranches) {
            map[branch.copyFrom] = branch.id;
          }
          // 轉換原先的 link map
          const data = nDesion.data || {};
          const nLinks = data.nLinks || [];
          data.links = nLinks.map(([bid, nid]) => [map[bid], nid]);
          delete data.nLinks;
          await this.dispatch('desion/updateDesionData', { id, data }, { root: true });
        }
      },
    }),
  },
};

export default m;
