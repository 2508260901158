<template>
  <v-dialog v-model="show">
    <div class="popup-confirm d-flex flex-column">
      <div class="content">{{ content }}</div>
      <div class="d-flex justify-space-between align-center">
        <div class="popupBtn posBtn" @click="clickPosBtn">{{ posText }}</div>
        <div class="popupBtn" @click="clickNegBtn">{{ negText }}</div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: Boolean,
    },
    content: {
      type: String,
      default: '',
    },
    posText: {
      type: String,
      default: '確定',
    },
    negText: {
      type: String,
      default: '取消',
    },
  },
  computed: {
    show: {
      set(v) {
        this.$emit('input', v);
        if (this.value !== v) {
          this.$emit('change', v);
        }
      },
      get() {
        return this.value;
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    clickPosBtn() {
      this.$emit('confirm');
      this.$emit('select', true);
    },
    clickNegBtn() {
      this.$emit('cancel');
      this.$emit('select', false);
    },
  },
};
</script>

<style scoped lang="scss">
.popup-confirm {
  background-color: white;
  border-radius: 11px;
  max-width: 247px;
  min-height: 92px;
}
.content {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  padding: 24px 5px 16px 5px;
  border-bottom: 1px solid #828282;
}
.popupBtn {
  font-size: 12px;
  text-align: center;
  width: 50%;
  min-height: 28px;
  padding: 6px;
  cursor: pointer;
}
.posBtn {
  border-right: 1px solid #828282;
}
</style>
<style lang="scss"></style>
