<template>
  <div class="action-panews-setvar" :style="outterStyle">
    <div class="d-flex top-area">
      <v-spacer></v-spacer>
      <button
        v-if="!lock"
        class="btn-delete"
        :style="deleteBtnStyle"
        @click="btnDelete"
      >
        &times;
      </button>
    </div>
    <div class="action-setvar">
      <div class="action-title" :style="titleStyle">
        設定屬性
      </div>
      <input
        type="text"
        class="edit-field input-name"
        v-model="varName"
        placeholder="名稱"
        @change="updateData"
        :disabled="lock"
      />
      <div class="var-operator">
        =
      </div>
      <input
        type="text"
        class="edit-field input-value"
        v-model="varValue"
        placeholder="內容"
        @change="updateData"
        :disabled="lock"
      />
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  components: {
  },
  props: {
    action: {
      type: Object,
    },
    parent: {
      type: Object,
    },
    color: {
      type: Object,
    },
    lock: {
      type: Boolean,
    },
  },
  data() {
    return {
      varName: '',
      varValue: '',
    };
  },
  computed: {
    actionData() {
      const { action } = this;
      if (action && action.data) {
        return action.data;
      }
      return {};
    },
    outterStyle() {
      return {
        borderColor: this.color.primary,
        backgroundColor: this.color.bgColor,
      };
    },
    titleStyle() {
      return {
        color: this.color.primary,
      };
    },
    commonBtnStyle() {
      return {
        borderColor: this.color.primary,
      };
    },
    deleteBtnStyle() {
      return {
        backgroundColor: this.color.primary,
      };
    },
  },
  watch: {
    action() {
      this.initAction();
    },
  },
  created() {
    this.initAction();
    this.updateData = debounce(this.updateData, 200);
  },
  mounted() {
  },
  methods: {
    initAction() {
      const { actionData } = this;
      this.varName = actionData.name || '';
      this.varValue = actionData.value || '';
    },
    updateData() {
      const { varName, varValue } = this;
      this.update({
        name: varName,
        value: varValue,
      });
    },
    // 儲存
    update(data = {}) {
      // eslint-disable-next-line no-unused-vars
      const { actionData } = this;
      const nData = {
        name: data.name || '',
        value: data.value || '',
      };
      this.$emit('update-data', nData);
    },
    // 刪除
    btnDelete() {
      this.$emit('delete');
    },
  },
};
</script>

<style scoped lang="scss">
$icon-size: 60px;
$border-width: 2px;
.w-100 {
  width: 100%;
}
.action-panews-setvar{
  border: 2px solid #517EFE;
  border-radius: 16px;;
}
.action-setvar {
  // box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 8px;
  .action-title{
    min-width: 70px;
    margin-left: 8px;
  }
  .edit-field{
    outline: none;
    // flex-grow: 1;
    width: 60px;
    margin-left: 4px;
    margin-right: 4px;
    background: white;
    border: 1px solid grey;
    padding: 0 4px 0 4px;
    border: 1px solid #ACA7A7;
    border-radius: 6.33px;
  }
  .input-value{
    flex-grow: 1;
    margin-right: 32px;
  }
  .var-operator{
    width: 20px;
    text-align: center;
  }
}
.btn-delete{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  width: 16px;
  height: 16px;
  color: #FFF;
  background-color: #517EFE;
  &:hover{
    opacity: 0.7;
  }
}
.top-area{
  position: relative;
  right: 3px;
  top: -5px;
  height: 0px;
}
</style>
