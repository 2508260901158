<template>
  <div
    class="next-item"
    :style="nextItemStyle"
    v-click-outside="onClickOutside"
  >
    <div class="next-icon">
      <v-icon>
        mdi-magnify
      </v-icon>
    </div>
    <v-menu
      bottom
      :nudge-bottom="30"
      :close-on-content-click="false"
      :close-on-click="false"
      v-model="show"
    >
      <template v-slot:activator="{ attrs }">
        <input type="text"
          :value="node.to"
          @input="onChangeValue"
          @click="showMenu"
          v-bind="attrs"
          ref="input"
        >
      </template>
      <v-sheet
          class="node-select"
          color="white"
      >
        <template v-if="show && !selectItem">
          <div
            v-for="(item, index) in menuItems"
            :key="index"
          >
            <div class="node-select-item" @click="onSelectSceneItem(item)">
              <v-icon size="16">
                mdi-folder-multiple
              </v-icon>
              <span class="ml-2">
                {{ item.sceneName }}
              </span>
              <v-spacer></v-spacer>
              <v-icon size="16">
                mdi-play
              </v-icon>
            </div>
          </div>
        </template>
        <template v-if="show && selectItem">
          <div
            v-for="item in subItems"
            :key="item.id"
          >
            <div class="node-select-item" @click="onClickPlotItem(selectItem, item)">
              <span class="ml-2">
                {{ item.name }}
              </span>
            </div>
          </div>
        </template>
      </v-sheet>
    </v-menu>
    <button class="next-remove-btn" @click="deleteNode()">
      <v-icon>
        mdi-close
      </v-icon>
    </button>
  </div>
</template>

<script>
import { groupBy } from 'lodash';

export default {
  props: {
    node: {
      type: Object,
    },
    height: {
      type: Number,
    },
  },
  data() {
    return {
      show: false,
      selectItem: null,
      clickLock: false,
    };
  },
  updated() {
    // console.log('updated');
    if (this.clickLock) {
      setTimeout(() => {
        // console.log('reset lock');
        this.clickLock = false;
      }, 100);
    }
  },
  computed: {
    menuItems() {
      return this.$store.getters['plot/nextNodeMenuItems'];
    },
    subItems() {
      if (this.selectItem) {
        return this.selectItem.items || [];
      }
      return [];
    },
    nextItemStyle() {
      const { height } = this;
      const style = {};
      if (height) {
        style.height = `${height}px`;
      }
      return style;
    },
  },
  methods: {
    showMenu() {
      this.show = true;
    },
    /**
     * @param {PointerEvent} e
     */
    onClickOutside(e) {
      if (this.show && !this.clickLock) {
        // console.log('click outside', this.clickLock, e);
        this.show = false;
        this.selectItem = null;
      }
      e.stopPropagation();
    },
    onChangeValue(e) {
      const val = e.target.value;
      this.$emit('change', e);
      this.$emit('update-data', {
        id: this.node.id,
        to: val,
        toTitle: val,
      });
    },
    onSelectSceneItem(item) {
      this.clickLock = true;
      this.selectItem = item;
      this.show = true;
      const to = `${item.sceneName}`;
      this.$emit('update-data', {
        id: this.node.id,
        to,
        toTitle: to,
      });
      // console.log('onSelectSceneItem');
    },
    onClickPlotItem(sceneItem, plot) {
      this.selectItem = null;
      this.clickLock = true;
      this.show = false;
      const to = `${sceneItem.sceneName}/${plot.name}`;
      this.$emit('update-data', {
        id: this.node.id,
        to,
        toTitle: to,
      });
    },
    deleteNode() {
      this.$emit('delete');
    },
  },
};
</script>

<style lang="scss" scoped>
.next-item{
  display: flex;
  border: 1px solid #32BEA6;
  background-color: #EAFFFA;
  color: #979797;
  align-items: center;
  padding: 4px;
  height: 40px;
  border-radius: 4px;
  width: 97%;
  .next-icon{
    display: flex;
  }
  .next-remove-btn{
    display: flex;
    &:hover{
      opacity: .8;
    }
    &:active{
      opacity: .6;
    }
  }
  input{
    display: flex;
    align-items: center;
    flex-grow: 1;
    width: 50%;
    outline: none;
    color: #616060;
    background-color: #FFFFFF;
    border: 1px solid #32BEA6;
    padding-left: 4px;
    padding-right: 4px;
    letter-spacing: 1px;
    // cursor: pointer;
  }
}
.node-select{
  min-width: 100px;
  // background: white;
  user-select: none;
}
.node-select-item{
  display: flex;
  padding: 4px;
  padding-left: 8px;
  background-color: white;
  cursor: default;
  &:hover{
    background-color: #EDEDED;
  }
}
</style>
