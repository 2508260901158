/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import Axios from 'axios';
import { getToken, getSettionId, setSettionId } from './nse-settings';
import $v from './event-bus';

const noDataCheck = process.env.VUE_APP_NO_API_DATA_CHECK === 'true';
const noApiTest = process.env.VUE_APP_NO_API_TEST === 'true';
/*
  nse-api.js，用於處理該網頁大部分的基本功能，包含故事的上傳下載等等
 */

/**
 * api 驗證用的 token
 * (登入後透過 setToken 設定)
 */

console.log('nse-api options:', {
  noDataCheck,
  noApiTest,
});

export const axiosInstance = Axios.create({
  baseURL: process.env.VUE_APP_NSE_API_BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
  // 每次傳送訊息前要加上 auth header
  const token = getToken();
  const sessionId = getSettionId();
  if (token) {
    config.headers.Authorization = `Bearer ${getToken()}`;
  }
  if (sessionId) {
    config.headers['x-vsid'] = sessionId;
  }
  return config;
});
axiosInstance.interceptors.response.use((res) => {
  setSettionId(res.headers['x-vsid']);
  return res;
}, (err) => {
  const res = err.response || {};
  const data = res.data || {};
  if (res.status === 403) {
    const payload = data.payload || {};
    if (payload.expire) {
      $v.$emit('token-expire');
    }
  }
  return Promise.reject(err);
});

export default axiosInstance;
