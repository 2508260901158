import Vue from 'vue';

/**
 * 主要處理的事件
 *
 * + layout/*: layout 上按鈕之類的事件
 * + resize : 當畫面更新時會觸發此事件
 */
export const vue = new Vue();
export default vue;
