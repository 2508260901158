/* eslint-disable no-use-before-define */
/* eslint-disable prefer-const */
import {} from 'lodash';

/* eslint-disable import/prefer-default-export */
/* eslint-disable quote-props */

/**
 * 此處建立的資料會被放在 action.data
 */
const actionCreateFunc = {
  'pa-news': {
    'play': (opts) => {
      const {
        text,
        audio,
      } = opts;
      return {
        text: text || '',
        audio: audio || '',
      };
    },
    'change-img': (opts) => {
      let {
        characters,
      } = opts;
      const {
        background,
        foreground,
      } = opts;
      if (Array.isArray(characters)) {
        characters = [...characters];
        if (characters.length === 0) {
          characters = [''];
        }
      } else {
        characters = [''];
      }
      return {
        /** @type {String[]} */
        'characters': characters,
        'background': background || '',
        'foreground': foreground || '',
      };
    },
    'set-var': (opts) => {
      let {
        name,
        value,
      } = opts;
      return {
        /** @type {String[]} */
        'name': name || '',
        'value': value || '',
      };
    },
  },
};

const desionCreateFunc = {
  'common': {
    // 不管對話內容直接指定下一幕的位置
    'specify': (opts) => ({
      toTitle: '',
      to: '',
    }),
    'closest-s': (opts) => {
      let {
        branches,
      } = opts;
      if (!branches) {
        branches = [];
      }
      const failback = createBasicBranch(failback);
      failback.order = 9999;
      failback.data = { name: '', t: 'f', sentences: [] };
      const failbackM = createBasicBranch(failbackM);
      failbackM.order = 9999;
      failbackM.data = { name: '', t: 'fm', sentences: [] };
      branches.push(failback);
      branches.push(failbackM);
      return {
        branches,
        nextNodes: [],
        links: [],
      };
    },
  },
};

export function createAction(group, type, opts = {}) {
  if (actionCreateFunc[group] && actionCreateFunc[group][type]) {
    const data = actionCreateFunc[group][type](opts);
    const action = {
      type,
      group,
      data,
    };
    return action;
  }
  return null;
}

export function createDesion(group, type, opts = {}) {
  if (desionCreateFunc[group] && desionCreateFunc[group][type]) {
    const data = desionCreateFunc[group][type](opts);
    const branches = data.branches || [];
    delete data.branches;
    const desion = {
      type,
      group,
      data,
    };
    return {
      desion,
      branches,
    };
  }
  return null;
}

export function createBasicBranch(opts = {}) {
  return {
    id: null,
    name: opts.name || '',
    data: {
      next: [],
    },
  };
}

export function createPlot(opts = {}) {
  return {
    name: opts.name || '',
  };
}

export function createEmptyScene() {
  return {
    name: '',
  };
}
