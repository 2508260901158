/* eslint-disable prefer-const */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
/**
 * @typedef {import('vuex').Module} VuexModule
 */

import { groupBy } from 'lodash';
import {
  createNseDataHandleMutations,
  createNseEventHandler,
  createNseCloneAction,
  createNseBasicActions,
} from '../helpers/store-tools';
import { filterItemName } from '../helpers/utils';
import * as nseApi from '../helpers/nse-api';

/**
 * @type {VuexModule}
 */
export const m = {
  namespaced: true,
  state: () => ({
    // 存所有的 plot
    plots: [],
    idMap: {},
    // group by parent
    groupByParent: {},
  }),
  getters: {
    namedPlots(state, getters, rootState) {
      return state.plots.filter((plot) => plot.name);
    },
    /**
     * 給 next node 選擇下一幕用，為了共用所以統一在 store 做
     */
    nextNodeMenuItems(state, getters, rootState) {
      const sceneIdMap = rootState.scene.idMap;
      const scenePlotMap = groupBy(getters.namedPlots, (item) => item.sceneId);

      const items = Object.keys(scenePlotMap).map((sceneId) => {
        const scene = sceneIdMap[sceneId];
        if (!scene.name) {
          return null;
        }
        return {
          sceneId: scene.id,
          sceneName: scene.name,
          items: scenePlotMap[scene.id],
        };
      });
      return items;
    },
  },
  mutations: {
    // 建立操作 plots 的相關 mutations
    ...createNseDataHandleMutations({
      name: 'plot',
      stateKey: 'plots',
      getId: (data) => data.id,
      getParentId: (data) => data.sceneId,
    }),
  },
  actions: {
    ...createNseEventHandler({
      createFuncName: 'createPlot',
      updateFuncName: 'updatePlot',
      deleteFuncName: 'deletePlot',
    }),
    ...createNseBasicActions({
      name: 'plot',
      createFunc: 'createPlot',
      updateFunc: 'updatePlot',
      updateDataFunc: 'updatePlotData',
      deleteFunc: 'deletePlot',

      handleOrderBy: 'sceneId',
      handleName: true,
    }),
    clonePlots: createNseCloneAction({
      name: 'plot',
      parentKey: 'sceneId',
      cloneKey: 'createPlot',
      cloneChildren: ['pre_action/clonePreActions', 'desion/cloneDesions'],
    }),
  },
};

export default m;
