<template>
  <div class="require-login-view">
    <div class="content">
      <v-btn @click="btnLogin" v-if="!authError">
        登入
      </v-btn>
      <div class="ma-4" v-if="authError">
        <v-btn @click="btnLogin" v-if="errorInfo.showLoginBtn">
          {{ errorInfo.loginBtnText || '登入其他帳號' }}
        </v-btn>
        <div class="error-message" v-if="errorInfo.message">
          {{errorInfo.message}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $v from '../helpers/event-bus';
import {
  openLoginWindow,
} from '../helpers/login';

export default {
  computed: {
    authError() {
      const { state } = this.$store;
      const { authError } = state;
      return authError;
    },
    errorInfo() {
      const { authError } = this;
      return authError || {};
    },
  },
  data() {
    return {
      message: '',
    };
  },
  methods: {
    btnLogin() {
      openLoginWindow(async () => {
        $v.$emit('login');
        await this.$store.dispatch('updateAccountInfo');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.require-login-view{
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .error-message{
    margin-top: 24px;
    max-width: 300px;
    text-align: center;
  }
}
</style>
