/**
 * 設定每個 actionView 在不同位置時候的配色
 */

export const colorPack = {
  preAction: {
    primary: '#517EFE',
    bgColor: '#F0F8FF',
  },
  reply: {
    primary: '#FF9900',
    bgColor: '#FFF6D8',
  },
  failback: {
    primary: '#888888',
    bgColor: '#EFEFEF',
  },
};

export const actionDefs = [
  {
    group: 'pa-news',
    type: 'play',
    key: 'action:pa-news/play',
    settings: {
      // 一般 plot 中添加 action 的部分
      toolbarIcon: 'mdi-microphone',
      toolbarColor: 'white',
      toolbarBG: '#517EFE',
      // 例句的 dialog 中添加 action 的部分
      ssDialogIcon: 'mdi-microphone',
      ssDialogColor: '#FF9900',
      ssDialogBG: 'white',
      // 基本描述
      title: '添加台詞',
      appendMsg: '添加台詞',
    },
  },
  {
    group: 'pa-news',
    type: 'change-img',
    key: 'action:pa-news/change-img',
    settings: {
      // 一般 plot 中添加 action 的部分
      toolbarIcon: 'mdi-panorama',
      toolbarColor: '#517EFE',
      toolbarBG: 'white',
      // 例句的 dialog 中添加 action 的部分
      ssDialogIcon: 'mdi-panorama',
      ssDialogColor: '#FF9900',
      ssDialogBG: 'white',
      // 基本描述
      title: '添加圖片',
      appendMsg: '添加圖片（輸入URL）',
    },
  },
  {
    group: 'pa-news',
    type: 'set-var',
    key: 'action:pa-news/set-var',
    settings: {
      // 一般 plot 中添加 action 的部分
      toolbarIcon: 'mdi-variable-box',
      toolbarColor: 'white',
      toolbarBG: '#517EFE',
      // 例句的 dialog 中添加 action 的部分
      ssDialogIcon: 'mdi-variable-box',
      ssDialogColor: '#FF9900',
      ssDialogBG: 'white',
      // 基本描述
      title: '設定屬性',
      appendMsg: '設定屬性',
    },
  },
];

export default colorPack;
