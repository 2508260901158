<template>
  <div class="editor-desion" v-if="desion">
    <div class="desion-header">
      <div class="left-side"></div>
      <div class="center-side">
        {{ settings.menuTitle }}
      </div>
      <div class="right-side">
        <button class="btn-delete" @click="deleteDesion">
          <v-icon>
            mdi-close
          </v-icon>
        </button>
      </div>
    </div>
    <div
      :is="view"
      :desion="desion"
      :branches="branches"
      :actions-map="actionsMap"
      :parent="parent"
      @update="onUpdate"
      @delete="onDelete"
    ></div>
  </div>
</template>

<script>
/* eslint-disable guard-for-in */
/* eslint-disable quote-props */
/* eslint-disable import/no-named-as-default */
import DesionCommonClosestS from './DesionCommonClosestS.vue';
import desionDefs from '../helpers/desion-settings';

const viewMap = {
  'common': {
    'closest-s': [
      'DesionCommonClosestS',
      DesionCommonClosestS,
    ],
  },
};

const components = {};
for (const group in viewMap) {
  for (const type in viewMap[group]) {
    const [name, view] = viewMap[group][type];
    components[name] = view;
  }
}

export default {
  // 根據 viewMap 自動產生
  components,
  props: {
    desion: {
      type: Object,
    },
    parent: {
      type: Object,
    },
  },
  computed: {
    view() {
      const { desion } = this;
      if (desion) {
        const { group, type } = desion;
        // console.log('view', { group, type, v: viewMap[group][type] });
        const view = viewMap[group][type];
        if (view && view[1]) {
          return view[1];
        }
      }
      console.warn('view not found');
      return 'div';
    },
    settings() {
      const { desion } = this;
      if (desion) {
        const { group, type } = desion;
        const data = desionDefs.find((item) => item.group === group && item.type === type) || {};
        return data.settings;
      }
      return null;
    },
    branches(v) {
      const { desion } = this;
      if (desion) {
        console.log('update branches', this.$store.state.branch.groupByParent[desion.id]);
        return this.$store.state.branch.groupByParent[desion.id] || [];
      }
      return [];
    },
    // reply_actions
    actionsMap() {
      const { branches } = this;
      const actionsMap = {};
      if (branches) {
        for (const branch of branches) {
          const { id } = branch;
          actionsMap[id] = this.$store.state.rep_action.groupByParent[id] || [];
        }
      }
      return actionsMap;
    },
  },
  data() {
    return {};
  },
  methods: {
    onUpdate(desionData) {
      // desion 資料比較複雜，所以沒有像 actionView 一樣
      // 統一在 actionView 進行更新
      // 而是讓下一層手動處理
    },
    onDelete() {
      this.deleteDesion();
    },
    async deleteDesion() {
      const { desion } = this;
      if (desion) {
        await this.$store.dispatch('desion/deleteDesion', desion.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-desion{
  .desion-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFC700;
    border: 1px solid #FFA800;
    margin-left: 10px;
    margin-right: 10px;
    padding: 6px;
    background-color: #FFF6D8;
    border-radius: 4px;
    & > *{
      flex: 1;
    }
  }
  .left-side{
    display: flex;
    justify-content: left;
  }
  .center-side{
    display: flex;
    justify-content: center;
  }
  .right-side{
    display: flex;
    justify-content: right;
  }
  .btn-delete:hover{
    opacity: .8;
  }
  .btn-delete:active{
    opacity: .8;
  }
}
</style>
