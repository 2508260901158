<template>
  <div class="plot-wrapper">
    <div class="plot">
      <div class="plot-title-bar">
        <!-- plot 標題 -->
        <div class="plot-title">
          <button class="plot-edit-btn" @click="editName = true">
            <v-icon
              class="plot-edit-icon"
              size="32"
              color="#517EFE"
            >
              mdi-square-edit-outline
            </v-icon>
          </button>
          <EditText
            v-model="tmpTitle"
            s-type="plot-name"
            :edit.sync="editName"
            placeholder="點擊填寫幕名稱"
            style="width: 100%"
            @change="changeTitle"
          >
          </EditText>
        </div>
        <!-- plot 標題列右邊的按鈕群 -->
        <div class="plot-tools">
          <template v-for="(btn, i) in toolbar">
            <span
              class="plot-tools-btn"
              :key="'B' + btn.key"
              @click="onToolbarBtn(i, btn, $event)"
            >
              <v-icon
                color="white"
                size="32"
              >
                {{btn.icon}}
              </v-icon>
            </span>
            <span
              class="plot-tools-divider"
              :key="'D' + btn.key"
              v-if="i !== toolbar.length - 1"
            />
          </template>
        </div>
      </div>
      <!-- 裝 action 和 desion 的區域 -->
      <div class="plot-main" v-if="scene && plot">
        <template v-for="action,i in preActions">
          <ActionView
            :action="action"
            :parent="plot"
            :key="action.id"
            place="preAction"
            @delete="deleteAction(i)"
            @update:action="updateAction(i, $event)"
          />
        </template>
        <StoryEditorFloatToolbar
          :plot-id="plot.id"
          @btn-click="onFloatBtn"
        />
        <v-divider></v-divider>
        <StoryEditorAddDesionTool
          :plot-id="plot.id"
          @btn-click="onFloatBtn"
        />
        <template v-for="desion,i in desions">
          <DesionView
            :desion="desion"
            :parent="plot"
            :key="desion.id"
            @delete="deleteDesion(i)"
            @update:desion="updateDesion(i, $event)"
          />
        </template>
      </div>
      <!--  -->
    </div>
  </div>
</template>

<script>
/* eslint-disable prefer-arrow-callback */
import { debounce } from 'lodash';
import { createAction, createDesion, createPlot } from '../helpers/data-create';
import { parseKeyString } from '../helpers/utils';
import StoryEditorFloatToolbar from './StoryEditorFloatToolbar.vue';
import StoryEditorAddDesionTool from './StoryEditorAddDesionTool.vue';
import ActionView from '@/components/ActionView.vue';
import DesionView from '@/components/DesionView.vue';

export default {
  components: {
    StoryEditorAddDesionTool,
    StoryEditorFloatToolbar,
    ActionView,
    DesionView,
  },
  props: {
    plot: {
      type: Object,
    },
  },
  computed: {
    title() {
      if (this.plot) {
        return this.plot.name || '';
      }
      return '';
    },
    scene() {
      return this.$store.getters['scene/scene'];
    },
    preActions() {
      const { id } = this.plot;
      return this.$store.state.pre_action.groupByParent[id] || [];
    },
    hasContent() {
      return this.preActions.length > 0;
    },
    desions() {
      const { id } = this.plot;
      return this.$store.state.desion.groupByParent[id] || [];
    },
  },
  data() {
    return {
      // 表示當前是否處於編輯模式
      tmpTitle: '',
      editName: false,
      toolbar: [
        {
          key: 'new',
          icon: 'mdi-shape-square-plus',
        },
        {
          key: 'copy',
          icon: 'mdi-content-copy',
        },
        {
          key: 'delete',
          icon: 'mdi-trash-can',
        },
      ],
    };
  },
  created() {
    this.changeTitle = debounce(this.changeTitle, 200);
  },
  mounted() {
    this.tmpTitle = this.title;
  },
  methods: {
    async changeTitle() {
      const plot = await this.$store.dispatch('plot/updatePlot', {
        ...this.plot,
        name: this.tmpTitle,
      });
      this.$emit('update:plot', plot);
    },
    // eslint-disable-next-line no-unused-vars
    deleteAction(i) {},
    // eslint-disable-next-line no-unused-vars
    updateAction(i, action) {},
    // eslint-disable-next-line no-unused-vars
    deleteDesion(i) {},
    // eslint-disable-next-line no-unused-vars
    updateDesion(i, desion) {},
    /**
     * 當 Plot 的按鈕(每個 plot 框的右上那些)觸發時
     */
    async onToolbarBtn(i, btn) {
      console.log('onToolbarBtn', i, btn);
      const { scene, plot } = this;
      if (btn.key === 'new') {
        const p = createPlot();
        p.sceneId = scene.id;
        const nPlot = await this.$store.dispatch('plot/createPlot', p);
        console.log('plot', nPlot);
        this.$emit('add-plot', nPlot);
      } else if (btn.key === 'copy') {
        const { loading } = this.$store.state;
        if (!loading) {
          this.$store.commit('SET_LOADING', true);
          try {
            this.$store.commit('SET_LOAD_MSG', '複製內容中...');
            const [nPlot] = await this.$store.dispatch('plot/clonePlots', {
              id: plot.id,
              to: scene.id,
            });
            console.log('new plot', nPlot);
          } catch (e) {
            console.error(e);
          }
          this.$store.commit('SET_LOADING', false);
        }
      } else if (btn.key === 'delete') {
        await this.$store.dispatch('plot/deletePlot', this.plot.id);
        this.$emit('delete');
      }
    },
    /**
     * 當浮動的工具列(每個 plot 最下面的那個)觸發時
     */
    // eslint-disable-next-line no-unused-vars
    onFloatBtn({ index, btn, $event }) {
      const opts = parseKeyString(btn.key);
      if (opts) {
        console.log('plot btn:', opts);
        if (opts.type === 'action') {
          const action = createAction(opts.group, opts.actionType);
          action.plotId = this.plot.id;
          this.$store.dispatch('pre_action/createPreAction', action);
        } else if (opts.type === 'desion') {
          const { desion, branches } = createDesion(opts.group, opts.desionType);
          desion.plotId = this.plot.id;
          console.log('plot ', { desion, branches });
          this.$store.dispatch('desion/createDesionWithBranches', {
            desion,
            branches,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$border-width: 3px;
.plot-wrapper{
  box-sizing: border-box;
  margin: 24px;
  padding: 0px;
  .plot{
    display: flex;
    flex-direction: column;
    font-weight: bold;
    border-radius: 30px;
    border: 3px solid #517EFE;
  }
  .plot-title-bar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #517EFE;
    margin: -1*$border-width -1*$border-width 0 -1*$border-width;
    border: 3px solid #517EFE;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.233);
    .plot-title{
      display: flex;
      flex-grow: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow : ellipsis;
      font-size: 24px;
      padding: 8px 24px 8px 24px;
    }
    .plot-tools{
      display: flex;
      margin: -1px;
      font-size: 24px;
      color: white;
      border-radius: 0 30px 30px 0;
      background-color: #517EFE;
      padding: 0px 18px 0px 6px;
    }
    .plot-tools-divider{
      width: 1px;
      height: 80%;
      margin-top: 3%;
      background-color: white;
    }
    .plot-tools-btn{
      padding: 8px;
      height: 100%;
      cursor: pointer;
    }
    .plot-tools-btn:hover{
      opacity: 0.6;
    }
    .plot-tools-btn:active{
      opacity: 0.4;
    }
  }
  .plot-main{
    padding: 8px 24px 8px 24px;
    // border-radius: 0 0 20px 20px;
    // border: 3px solid #517EFE;
    // border-top: 0;
    & > *{
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }
  .plot-edit-btn{
    outline: none;
    margin-right: 8px;
  }
  .plot-edit-icon{
    justify-self: center;
  }
}
</style>
