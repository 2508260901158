let token = '';
let sessionId = '';

export function getToken() {
  return token;
}

export function setToken(nToken) {
  token = nToken;
  return token;
}

export function getSettionId() {
  return sessionId;
}

export function setSettionId(nSessionId) {
  // if (nSessionId !== sessionId) {
  //   console.log('new sessionId', nSessionId);
  // }
  sessionId = nSessionId;
  return sessionId;
}
