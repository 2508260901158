<template>
  <div class="scene-editor">
    <PopupConfirm
      v-model="deleteConfirm"
      @select="onConfirmSelect"
    />
    <div class="scene-plots">
      <template v-for="plot, i in plots">
        <StoryEditorPlot
          :plot="plot"
          :key="plot.id"
          @delete="deletePlot(i)"
          @update:plot="updatePlot(i, $event)"
          @add-plot="addPlot(i, $event)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { createPlot } from '../helpers/data-create';
import StoryEditorPlot from './StoryEditorPlot.vue';
import PopupConfirm from './PopupConfirm.vue';

export default {
  components: {
    StoryEditorPlot,
    PopupConfirm,
  },
  computed: {
    scene() {
      return this.$store.getters['scene/scene'];
    },
    plots() {
      const { scene } = this;
      // key: sceneId => value: plots
      const group = this.$store.state.plot.groupByParent;
      if (scene) {
        return group[scene.id] || [];
      }
      return [];
    },
  },
  watch: {
    plots() {
      this.$nextTick(() => {
        this.checkPlotsCount();
      });
    },
  },
  data() {
    return {
      deleteConfirm: false,
    };
  },
  methods: {
    async updatePlot(i, plot) {
      // await this.$store.dispatch('plot/updatePlot', plot);
    },
    async addPlot(i, plot) {
      // eslint-disable-next-line no-param-reassign
      // plot.sceneId = this.scene.id;
      // await this.$store.dispatch('plot/createPlot', plot);
    },
    onConfirmSelect() {
      console.log('onConfirmSelect');
    },
    async onDelete(i) {
      await this.checkPlotsCount();
    },
    async checkPlotsCount() {
      const { plots, scene } = this;
      if (plots.length === 0) {
        // 當場景中的 plot 被刪光，自動新增一個空的
        const plot = createPlot();
        plot.sceneId = scene.id;
        await this.$store.dispatch('plot/createPlot', plot);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .scene-editor{

  }
</style>
