<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="600"
    v-model="show"
  >
    <div class="story-publish-dialog">
      <div class="dialog-header">
        故事發佈 - {{ publishStoryId }}
      </div>
      <div
        class="dialog-main"
      >
        <v-btn
          :loading="loading"
          dark
          color="#FFA800"
          @click="btnPublish"
        >
          發佈
        </v-btn>
        <div class="publish-message">
          <v-alert
            dense
            :type="err ? 'error':'success'"
            v-if="message"
          >
            {{message}}
          </v-alert>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { debounce, keyBy } from 'lodash';
import { createAction } from '../helpers/data-create';
import { delay } from '../helpers/utils';

export default {
  props: {
    value: {
      type: Boolean,
    },
    storyId: {
      type: String,
      default: '',
    },
  },
  computed: {
    show: {
      set(v) {
        this.$emit('input', v);
        if (this.value !== v) {
          this.$emit('change', v);
        }
      },
      get() {
        return this.value;
      },
    },
    publishStoryId() {
      const { storyId } = this;
      if (storyId) {
        return storyId;
      }
      return this.$store.state.story.storyId;
    },
  },
  data() {
    return {
      loading: false,
      err: false,
      message: '',
    };
  },
  mounted() {
  },
  methods: {
    async btnPublish() {
      if (!this.loading) {
        try {
          this.loading = true;
          this.err = false;
          this.message = '';
          await delay(1500);
          await this.$store.dispatch('story/publishStory', this.publishStoryId);
          this.message = '發佈已完成';
        } catch (e) {
          this.err = true;
          if (e.response) {
            const { data } = e.response;
            if (data.type === 'BuilderError' || data.type === 'Parameter') {
              this.message = data.message;
            }
          }
          if (!this.message) {
            this.message = '發佈失敗';
          }
        }
        this.loading = false;
      }
    },
    close() {
      this.$emit('input', false);
      this.$emit('change', false);
    },
  },
};
</script>

<style scoped lang="scss">
.story-publish-dialog{
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: white;
  gap: 16px;
  .dialog-header{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    cursor: pointer;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #FFA800;
    background-color: #FFF6D8;
    color: #FFA800;
    &:hover{
      opacity: .8;
    }
    &:active{
      opacity: .6;
    }
  }
  .dialog-main{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 16px;
  }
  .publish-message{
    flex-grow: 1;
    margin-bottom: -16px;
  }
}
</style>
