/**
 * @typedef {Obejct} DesionDef
 * @property {String} group
 * @property {String} type
 * @property {String} key
 * @property {Boolean} last 若 last 為 true 表示該 desion 只能出現在所有 desion 的最後
 * @property {Function} rule 用於判斷特定的位置能不能新增當前的 desion
 */

/**
 * @type {DesionDef[]}
 */
export const desionDefs = [
  {
    group: 'common',
    type: 'closest-s',
    key: 'desion:common/closest-s',
    last: true,
    rule: null,
    settings: {
      menuTitle: '相似度分析',
    },
  },
];

export default desionDefs;
