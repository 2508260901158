<template>
  <div class="desion-closest-s" ref="outer">
    <template v-if="largeMode">
      <svg xmlns="http://www.w3.org/2000/svg" :width="svgWidth" :height="svgHeight">
        <template v-for="p in nextLinks">
          <line
            :key="`${p[0]}-${p[1]}`"
            class="link-line"
            :title="`branch ${p[0]}-${p[1]}`"
            :x1="idPointMap[p[0]].x + size.linkSize - 2"
            :y1="idPointMap[p[0]].y"
            :x2="idPointMap[p[1]].x - size.linkSize * 0.6"
            :y2="idPointMap[p[1]].y"
          />
        </template>
        <!-- branch node -->
        <circle
          class="link-point branch-link-point"
          :class="{'point-selected': point === selectedPoint}"
          v-for="point, i in linkPointsL"
          :key="'p_l' + i"
          :cx="point.x"
          :cy="point.y"
          :r="size.linkSize"
          @click="onLinkClick(point)"
        />
        <!-- failback -->
        <circle
          class="link-point failback-link-point"
          :class="{'point-selected': linkPointF1 === selectedPoint}"
          :cx="linkPointF1.x"
          :cy="linkPointF1.y"
          :r="size.linkSize"
          @click="onLinkClick(linkPointF1)"
        />
        <!-- failbackM -->
        <circle
          class="link-point failback-link-point"
          :class="{'point-selected': linkPointFM === selectedPoint}"
          :cx="linkPointFM.x"
          :cy="linkPointFM.y"
          :r="size.linkSize"
          @click="onLinkClick(linkPointFM)"
        />
        <!-- next node -->
        <circle
          class="link-point next-link-point"
          v-for="point, i in linkPointsR"
          :class="{'point-selected': point === selectedPoint}"
          :key="'p_r' + i"
          :cx="point.x"
          :cy="point.y"
          :r="size.linkSize"
          @click="onLinkClick(point)"
        />
        <!-- SelectedPoint 選擇的點再畫一個圓形 -->
        <!-- <template>
          <circle
            class="link-point branch-link-point point-selected"
            v-if="selectedPoint"
            :cx="idPointMap[selectedPoint.id].x"
            :cy="idPointMap[selectedPoint.id].y"
            :r="size.linkSize"
            @click="onLinkClick(selectedPoint)"
          />
        </template> -->
        <!-- END == SelectedPoint == -->
        <foreignObject
          :x="leftArea.x"
          :y="leftArea.y"
          :width="leftArea.width"
          :height="leftArea.height"
        >
          <div xmlns="http://www.w3.org/1999/xhtml">
            <div class="branch-area g-main" :style="branchAreaStyle">
              <div class="branch-header" :style="branchHStyle">
                <button class="branch-collapse-btn">
                  <v-icon color="#FF9900">
                    mdi-menu-down
                  </v-icon>
                </button>
                <div class="branch-title">
                  分支走向管理
                </div>
                <div class="title-placeholder" :style="titlePlaceholderStyle"></div>
              </div>
              <div
                class="branch-item"
                :style="branchIStyle"
                v-for="branch,i in nBranches"
                :key="i"
                v-long-press="400"
                @long-press-start="openBranchDetails(branch.id, i)"
                @click="startToEditBranch(i)"
              >
                <div class="branch-en-number">
                  {{enNum(i)}}
                </div>
                <div class="branch-title" v-if="branchEditIndex !== i">
                  {{branch.name}}
                  ( 長按開啟 )
                </div>
                <div class="branch-title" v-if="branchEditIndex === i">
                  <input
                    ref="editBranchName"
                    type="text"
                    class="input-branch-title"
                    v-model="branchNameTmp"
                    @change="onEditBranchName(i, branchNameTmp)"
                    @blur="branchEditIndex = -1"
                  />
                </div>
                <div class="title-placeholder">
                  <button class="branch-remove-btn" @click.stop="removeBranch(i)">
                    <v-icon>
                      mdi-close
                    </v-icon>
                  </button>
                </div>
              </div>
              <div
                class="branch-item branch-add"
                :style="branchIStyle"
                @click="btnCreateBranch"
              >
                <div class="btn-branch-add">
                  <v-icon color="#FFC700">
                    mdi-plus
                  </v-icon>
                </div>
              </div>
            </div>
            <!-- ============== -->
            <div class="branch-area g-failback" :style="branchAreaStyle">
              <div class="branch-header" :style="branchHStyle">
                <button class="branch-collapse-btn">
                  <v-icon color="#898989">
                    mdi-menu-down
                  </v-icon>
                </button>
                <div class="branch-title">
                  無法辨識（未滿三次）
                </div>
                <div class="title-placeholder" :style="titlePlaceholderStyle"></div>
              </div>

              <div class="failback-actions" ref="fActions">
                <ActionView
                  v-for="action,j in failbackReply"
                  :key="j"
                  place="failback"
                  :action="action"
                  :style="failbackRStyle"
                  @update:action="onFReplyUpdate(j, $event)"
                  @delete="onFReplyDelete(j, $event)"
                />
              </div>

              <div
                class="branch-item"
                v-for="aInfo,j in aActions"
                :key="j"
                :style="branchIStyle"
                @click="addReplyAction(failback.id, aInfo)"
              >
                <div class="branch-plus-icon" :style="branchPlusIconStyle">
                  <v-icon :color="color.primary">
                    mdi-plus
                  </v-icon>
                </div>
                <div class="branch-title">
                  {{aInfo.settings.appendMsg}}
                </div>
                <div class="title-placeholder"></div>
              </div>
            </div>
            <!-- ============== -->
            <div class="branch-area g-failback" :style="branchAreaStyle">
              <div class="branch-header" :style="branchHStyle">
                <button class="branch-collapse-btn">
                  <v-icon color="#898989">
                    mdi-menu-down
                  </v-icon>
                </button>
                <div class="branch-title">
                  無法辨識（已滿三次）
                </div>
                <div class="title-placeholder" :style="titlePlaceholderStyle"></div>
              </div>

              <div class="failback-actions" ref="fmActions">
                <ActionView
                  v-for="action,j in failbackMReply"
                  :key="j"
                  place="failback"
                  :action="action"
                  :style="failbackRStyle"
                  @update:action="onFMReplyUpdate(j, $event)"
                  @delete="onFMReplyDelete(j, $event)"
                />
              </div>

              <div
                class="branch-item"
                v-for="aInfo,j in aActions"
                :key="j"
                :style="branchIStyle"
                @click="addReplyAction(failbackM.id, aInfo)"
              >
                <div class="branch-plus-icon" :style="branchPlusIconStyle">
                  <v-icon :color="color.primary">
                    mdi-plus
                  </v-icon>
                </div>
                <div class="branch-title">
                  {{aInfo.settings.appendMsg}}
                </div>
                <div class="title-placeholder"></div>
              </div>
            </div>
          </div>
        </foreignObject>
        <foreignObject
          :x="rightArea.x"
          :y="rightArea.y"
          :width="rightArea.width"
          :height="rightArea.height"
        >
          <div xmlns="http://www.w3.org/1999/xhtml">
            <div class="next-area" :style="branchAreaStyle">
              <div class="next-header" :style="nextHStyle" @click="appendNextNode()">
                <div class="title-placeholder" :style="titlePlaceholderStyle"></div>
                <div class="branch-title">
                  指定下一幕
                </div>
                <button class="next-append-btn">
                  <v-icon color="white">
                    mdi-plus
                  </v-icon>
                </button>
              </div>
              <div class="next-item-list">
                <DesionNextNode
                  v-for="node,i in nextNodes"
                  :key="i"
                  class="next-item"
                  :style="branchIStyle"
                  :node="node"
                  @update-data="onNextNodeUpdate(i, $event)"
                  @delete="deleteNextNode(i)"
                />
              </div>
            </div>
          </div>
        </foreignObject>
      </svg>
    </template>
    <template v-else>
      <div>
        分支畫面暫時不支援小螢幕...
      </div>
    </template>
    <BranchSEditDialog
      v-model="dSentences.show"
      :branchId="dSentences.branchId"
      @update="onBranchDialogUpdate(dSentences.index, $event)"
    />
  </div>
</template>

<script>
/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
/* eslint-disable quote-props */
/* eslint-disable func-names */
/* eslint-disable no-else-return */
import { generate as genSUUID } from 'short-uuid';
import { debounce, keyBy } from 'lodash';
import { colorPack } from '../helpers/action-settings';
import { createAction, createBasicBranch } from '../helpers/data-create';
import { enNum } from '../helpers/utils';
import bus from '../helpers/event-bus';
import ActionView from './ActionView.vue';
import BranchSEditDialog from './BranchSEditDialog.vue';
import DesionNextNode from './DesionNextNode.vue';

/*
  計算屬性相關描述：
  因為計算屬性太多
  所以部分計算屬性有標示 #1 #2 #3 ...
  該標記表示計算屬性的順序，數字較小的計算屬性不應讀取數字較大的計算屬性
  (#1 不應讀取 #3 的資料會迴圈)

  xxxxStyle 順序一律為 #4
 */

/* eslint-disable no-param-reassign */
export default {
  components: {
    ActionView,
    BranchSEditDialog,
    DesionNextNode,
  },
  props: {
    desion: {
      type: Object,
    },
    branches: {
      type: Array,
    },
    actionsMap: {
      type: Object,
    },
    parent: {
      type: Object,
    },
  },
  computed: {
    /**
     * 可添加的對白列表
     */
    aActions() {
      return this.$store.state.story.availableActions;
    },
    /**
     * action 配色
     */
    color() {
      return colorPack.failback;
    },
    largeMode() {
      // 當螢幕夠大時應為 true
      return true;
    },
    desionId() {
      const { desion } = this;
      return desion.id;
    },
    desionData() {
      const { desion } = this;
      if (desion) {
        return desion.data || {};
      }
      return {};
    },
    // failback() {
    //   return this.branches.find((b) => b.data && b.data.t === 'f');
    // },
    failbackReply() {
      if (this.failback) {
        return this.actionsMap[this.failback.id] || [];
      }
      return [];
    },
    // failbackM() {
    //   return this.branches.find((b) => b.data && b.data.t === 'fm');
    // },
    failbackMReply() {
      if (this.failbackM) {
        return this.actionsMap[this.failbackM.id] || [];
      }
      return [];
    },
    /**
     * branch id => position
     * #4
     */
    idPointMap() {
      const {
        nBranches,
        failback,
        failbackM,
        nextNodes,

        linkPointsL,
        linkPointsR,
        linkPointF1,
        linkPointFM,
      } = this;
      const map = new Proxy({}, {
        get(obj, key) {
          if (obj[key]) return obj[key];
          return {
            x: 0,
            y: 0,
          };
        },
      });
      for (let i = 0; i < nBranches.length; i += 1) {
        const branch = nBranches[i];
        const point = linkPointsL[i];
        map[branch.id] = point;
      }
      for (let i = 0; i < nextNodes.length; i += 1) {
        const nextNode = nextNodes[i];
        const point = linkPointsR[i];
        map[nextNode.id] = point;
      }
      map[failback.id] = linkPointF1;
      map[failbackM.id] = linkPointFM;
      return map;
    },
    /**
     * #1
     * 更新寬度後第一個計算的數值
     * 左邊分支區域的大小和位置資訊
     */
    leftArea() {
      const {
        size,
        aActions,
        nBranches,
        svgWidth,
        failbackActionsHeight,
        failbackMActionsHeight,
      } = this;
      const { rBranchWidth } = size;
      const bCount = nBranches.length;
      const mw = svgWidth > 900 ? 900 : svgWidth;
      const width = rBranchWidth * mw;
      // + 三個主要 header (branch + failback + failbackM) 之間的兩個間隔
      // + 三個主要 header (branch + failback + failbackM)
      // + branch-item 之間的間隔
      // + 每個 branch-item 的高度
      // + failback 的 action view 區域的高度
      // + failbackM 的 action view 區域的高度
      const height = size.gap2 * 2
        + size.branchHeadHeight * 3
        + size.gap1 * (bCount + 2 + aActions.length * 2)
        + size.branchHeight * (bCount + 1 + aActions.length * 2)
        + failbackActionsHeight
        + failbackMActionsHeight;

      return {
        x: size.padding,
        y: size.padding,
        width,
        height,
      };
    },
    /**
     * #1
     * 更新寬度後第一個計算的數值
     * 右邊連結區域的主要位置資訊
     */
    rightArea() {
      const {
        size,
        svgWidth,
        nextNodes,
      } = this;
      const { rNextLinkWidth } = size;
      const nCount = nextNodes.length;
      const mw = svgWidth > 900 ? 900 : svgWidth;
      const width = rNextLinkWidth * mw;
      const height = size.gap2 * 2
        + size.gap1 * (nCount + 1)
        + size.branchHeight * nCount;
      return {
        x: svgWidth - size.padding - width,
        y: size.padding,
        width,
        height,
      };
    },
    branchAreaStyle() {
      const { size } = this;
      return {
        marginBottom: `${size.gap2}px`,
      };
    },
    // branch-header 的相關 style
    branchHStyle() {
      const { size } = this;
      return {
        height: `${size.branchHeadHeight}px`,
      };
    },
    // branch-item 的相關 style
    branchIStyle() {
      const { size } = this;
      return {
        marginTop: `${size.gap1}px`,
        height: `${size.branchHeight}px`,
      };
    },
    // next-header 的相關 style
    nextHStyle() {
      const { size } = this;
      return {
        height: `${size.branchHeadHeight}px`,
        marginBottom: `${size.gap2}px`,
      };
    },
    /** 每個 failback 的 reply 帶有的 style */
    failbackRStyle() {
      const { size } = this;
      return {
        marginTop: `${size.gap1}px`,
      };
    },
    titlePlaceholderStyle() {
      const { size } = this;
      return {
        width: size.iconSize1,
      };
    },
    branchPlusIconStyle() {
      return {
        borderColor: this.color.primary,
      };
    },
    /**
     * #2
     */
    nextPointCP() {
      const { size, rightArea } = this;
      const { y } = rightArea;
      const branchLinkGap = size.gap1 + size.branchHeight;
      // 第一個連結的 Y 座標
      const nextLinkY = y + size.branchHeadHeight
        + size.branchHeight * 0.5
        + size.gap2;
      return {
        nextLinkY,
        branchLinkGap,
      };
    },
    /**
     * #2
     * 計算連結點時通用的部分
     */
    linkPointCP() {
      const { size, nBranches, leftArea } = this;
      // 第一個連結的 Y 座標
      const branchLinkY = leftArea.y
        + size.branchHeadHeight + (size.branchHeight / 2)
        + size.gap1;
      const branchLinkGap = size.gap1 + size.branchHeight;
      // 第一個連結的 Y 座標
      const failbackLinkY = branchLinkY
        + nBranches.length * (size.gap1 + size.branchHeight)
        + size.branchHeight * 0.5
        + branchLinkGap;
      return {
        branchLinkY,
        branchLinkGap,
        failbackLinkY,
      };
    },
    /**
     * #3
     * 左邊的每個主分支的連結點座標
     */
    linkPointsL() {
      const {
        nBranches,
        leftArea,
        linkPointCP,
      } = this;
      const points = [];
      const x = leftArea.x + leftArea.width;
      const { branchLinkY, branchLinkGap } = linkPointCP;

      let y = branchLinkY;
      for (let i = 0; i < nBranches.length; i += 1) {
        const branch = nBranches[i];
        points.push({
          id: branch.id,
          x,
          y,
          p: 'L',
        });
        y += branchLinkGap;
      }
      return points;
    },
    /**
     * #3
     * 右邊的每個連結點座標
     */
    linkPointsR() {
      const {
        nextNodes,
        rightArea,
        nextPointCP,
      } = this;
      const { x } = rightArea;
      const { nextLinkY, branchLinkGap } = nextPointCP;
      const points = [];

      let y = nextLinkY;
      for (let i = 0; i < nextNodes.length; i += 1) {
        const next = nextNodes[i];
        points.push({
          id: next.id,
          x,
          y,
          p: 'R',
        });
        y += branchLinkGap;
      }
      return points;
    },
    /**
     * #3
     * failback 的連結點
     */
    linkPointF1() {
      const {
        failback,
        leftArea,
        linkPointCP,
      } = this;
      if (!failback) {
        return {
          id: -1, x: 0, y: 0, p: 'L',
        };
      }
      const x = leftArea.x + leftArea.width;
      const y = linkPointCP.failbackLinkY;
      return {
        id: failback.id,
        x,
        y,
        p: 'L',
      };
    },
    // failbackM 的連結點
    linkPointFM() {
      const {
        aActions,
        size,
        leftArea,
        linkPointCP,
        failbackM,
        failbackReply,
        failbackActionsHeight,
      } = this;
      if (!failbackM) {
        return {
          id: -1, x: 0, y: 0, p: 'L',
        };
      }
      const {
        failbackLinkY,
        branchLinkGap,
      } = linkPointCP;
      const x = leftArea.x + leftArea.width;
      const y = failbackLinkY
        + failbackActionsHeight
        + size.branchHeadHeight
        + branchLinkGap * aActions.length
        + size.gap2
        + (failbackReply.length === 0 ? 1 - size.gap1 : 0);
      return {
        id: failbackM.id,
        x,
        y,
        p: 'L',
      };
    },
    svgHeight() {
      const { leftArea, rightArea } = this;
      return Math.max(leftArea.height, rightArea.height);
    },
  },
  watch: {
    'branches': function () {
      this.initBranches();
    },
    'desionData': {
      handler(desionData) {
        this.nextNodes = desionData.nextNodes || [];
        this.nextLinks = desionData.links || [];
      },
      immediate: true,
    },
    'nBranches.length': {
      handler() {
      },
      immediate: true,
    },
    'nBranches': {
      handler() {
      },
      immediate: true,
    },
    'failback': function () {
      this.updateFActionsHeight();
    },
    'failbackReply': function () {
      this.updateFActionsHeight();
    },
    'failbackM': function () {
      this.updateFMActionsHeight();
    },
    'failbackMReply': function () {
      this.updateFMActionsHeight();
    },
  },
  data() {
    return {
      size: {
        padding: 10,
        branchHeadHeight: 40,
        branchHeight: 40,
        rBranchWidth: 0.5, // rate
        rNextLinkWidth: 0.35, // rate
        iconSize1: 24,
        /** failback 的台詞之間 或 各個 branches 之間的間隔 */
        gap1: 10,
        /** 三大塊分支之間的間隔 */
        gap2: 30,
        linkSize: 10,
      },
      /**
       * 上一個點擊的連結點
       */
      selectedPoint: null,
      /**
       * 該值會根據畫面大小更新
       * @see updateWidth
       */
      svgWidth: 700,
      branchesExpend: false,
      /** 編輯中的 branch 的 index */
      branchEditIndex: -1,
      /** 編輯中的 branch 的 name */
      branchNameTmp: '',
      failbackActionsHeight: 0,
      failbackMActionsHeight: 0,
      /**
       * nBranches 和 failback 是從 branches 過濾而來
       */
      nBranches: [],
      failback: {
        id: -1,
      },
      failbackM: {
        id: -1,
      },

      nextNMap: new Map(),
      /**
       * 右邊所有的 next 節點
       */
      nextNodes: [],
      // 此處包含的資料，每一個 item 表示一條線
      nextLinks: [],
      /**
       * 編輯例句的 dialog
       */
      dSentences: {
        show: false,
        branchId: null,
        index: -1,
      },
      idgen: 0,
    };
  },
  created() {
    this.initBranches();
    const resizeFunc = this.updateWidth.bind(this);
    this.$resizeFunc = resizeFunc;

    this.updateFActionsHeight = debounce(this.updateFActionsHeight, 20);
    this.updateFMActionsHeight = debounce(this.updateFMActionsHeight, 20);
    this.updateDesionData = debounce(this.updateDesionData, 200);
  },
  mounted() {
    bus.$on('resize', this.$resizeFunc);

    this.$nextTick(() => {
      this.$resizeFunc();
    });
    // this.updateFActionsHeight();
  },
  beforeDestroy() {
    bus.$off('resize', this.$resizeFunc);
  },
  methods: {
    getBranch(id) {
      return this.$store.state.branch.idMap[id];
    },
    initBranches() {
      const nBranches = [];
      let failback = null;
      let failbackM = null;
      for (const branch of this.branches) {
        const data = branch.data || {};
        switch (data.t) {
          case 'f':
            console.log('found branch F');
            failback = branch;
            break;
          case 'fm':
            console.log('found branch FM');
            failbackM = branch;
            break;
          case 'b':
          default:
            nBranches.push(branch);
            break;
        }
      }
      this.nBranches = nBranches;
      this.failback = failback;
      this.failbackM = failbackM;
    },
    craeteFailbackBranch(t = 'f') {
      const branch = createBasicBranch();
      branch.desionId = this.desionId;
      branch.data = {
        t,
        sentences: [],
        next: [],
        comment: '',
      };
      // #update_desion
      this.$store.dispatch('branch/createBranch', branch);
    },
    /**
     * @param {*} b branch
     * @param {*} i index
     */
    openBranchDetails(branchId, i) {
      console.log('open branch details', branchId, i);
      this.dSentences.show = true;
      this.dSentences.branchId = branchId;
      this.dSentences.index = i;
    },
    async onBranchDialogUpdate(i, branch) {
      console.log('onBranchDialogUpdate', i, branch);
      await this.$store.dispatch('branch/updateBranch', branch);
    },
    /**
     * 主要是在點選分支要編輯名稱的地方
     */
    startToEditBranch(n) {
      if (this.branchEditIndex >= 0) {
        // 如果原本正在編輯另一個，要先保存
        this.updateBranchName(n, this.branchNameTmp);
      }

      this.branchNameTmp = this.nBranches[n].name;
      this.branchEditIndex = n;
      // 開始編輯狀態後要自動 focus 輸入框
      this.$nextTick(() => {
        const { editBranchName } = this.$refs;
        const element = editBranchName[0];
        if (element) {
          element.focus();
        }
      });
    },
    onEditBranchName(index, name) {
      const branch = this.nBranches[index];
      this.updateBranch(branch.id, { name });
    },
    async removeBranch(i) {
      await this.$store.dispatch('branch/deleteBranch', this.nBranches[i].id);
    },
    /**
     * 避免同個 branch 快速更新
     */
    updateBranchData(branchId, branchData) {
      if (!this.$upBFuncD) {
        this.$upBFuncD = {};
      }
      if (!this.$upBFuncD[branchId]) {
        this.$upBFuncD[branchId] = debounce((id, data) => {
          this.$store.dispatch('branch/updateBranchData', { id, data });
          delete this.$upBFuncD[id];
        }, 200);
      }
      this.$upBFuncD[branchId](branchId, branchData);
    },
    /**
     * 避免同個 branch 快速更新
     */
    updateBranch(branchId, patch) {
      if (!this.$upBFuncS) {
        this.$upBFuncS = {};
      }
      if (!this.$upBFuncS[branchId]) {
        this.$upBFuncS[branchId] = debounce((id, patchData) => {
          this.$store.dispatch('branch/updateBranch', { ...patchData, id });
          delete this.$upBFuncS[id];
        }, 200);
      }
      this.$upBFuncS[branchId](branchId, patch);
    },
    /**
     * 偵測 $refs.outer 的寬度並設置 svg 的大小
     * 基本在 window.resize 時才執行
     */
    updateWidth() {
      console.log('updateWidth', this);
      const { outer } = this.$refs;
      if (outer) {
        const w = outer.clientWidth;
        // const h = outer.clientHeight;
        console.log('new w:', w);
        this.svgWidth = w;
      } else {
        console.log('outer not found', this.$refs);
      }
    },
    enNum(num) {
      return enNum(num);
    },
    updateFActionsHeight() {
      // console.log('updateFActionsHeight');
      this.$nextTick(() => {
        const { gap1 } = this.size;
        const { fActions } = this.$refs;
        if (fActions) {
          this.failbackActionsHeight = fActions.getBoundingClientRect().height + gap1;
        }
      });
    },
    updateFMActionsHeight() {
      // console.log('updateFMActionsHeight');
      this.$nextTick(() => {
        const { gap1 } = this.size;
        const { fmActions } = this.$refs;
        if (fmActions) {
          this.failbackMActionsHeight = fmActions.getBoundingClientRect().height + gap1;
        }
      });
    },
    deleteNextNode(i) {
      const { nextNodes } = this;
      const { id } = nextNodes[i];
      nextNodes.splice(i, 1);
      // eslint-disable-next-line no-unused-vars
      this.nextLinks = this.nextLinks.filter(([bid, nid]) => nid !== id);
      this.updateDesionData();
    },
    appendNextNode() {
      const { nextNodes } = this;
      const next = {
        id: genSUUID() + nextNodes.length,
        toTitle: '',
        to: '',
      };
      nextNodes.push(next);
      this.updateDesionData();
    },
    onNextNodeUpdate(i, data) {
      const { nextNodes } = this;
      nextNodes[i] = data;
      this.updateDesionData();
    },
    updateDesionData() {
      const {
        desionId,
        desionData,
        nextNodes,
        nextLinks,
      } = this;
      const nodeMap = keyBy(nextNodes, (node) => node.id);
      // 避免非同步狀態導致的問題
      // 自動移除沒對應到的 link
      const removeLinkSet = new Set();
      // eslint-disable-next-line no-unused-vars
      for (const [bid, nid] of nextLinks) {
        if (!nodeMap[nid]) {
          removeLinkSet.add(nid);
        }
      }
      // eslint-disable-next-line no-unused-vars
      const links = nextLinks.filter(([bid, nid]) => !removeLinkSet.has(nid));
      const data = {
        ...desionData,
        nextNodes,
        links,
      };
      this.$store.dispatch('desion/updateDesionData', {
        id: desionId,
        data,
      });
    },
    onLinkClick(p) {
      const sp = this.selectedPoint;
      if (sp) {
        // 若已選擇其中一個點
        if (sp.id === p.id) {
          // 如果兩次點的是同一個點
          this.selectedPoint = null;
        } else {
          let p1 = p;
          let p2 = sp;
          if (p1.p !== p2.p) {
            // 確保兩個點是左跟右
            if (p1.p !== 'L') {
              // 確保 p1 在左邊
              p1 = sp;
              p2 = p;
            }
            this.switchLink(p1.id, p2.id);
            this.selectedPoint = null;
          } else {
            // 如果兩次點同一邊的點，則改選取新的點
            this.selectedPoint = p;
          }
        }
      } else {
        // 若沒選擇第一個點，則選取該點
        this.selectedPoint = p;
      }
    },
    /**
     * @param {Number} id1 左邊的點
     * @param {Number} id2 右邊的點
     */
    switchLink(id1, id2) {
      console.log('switchLink', id1, id2);
      const { nextLinks } = this;
      const i = nextLinks.findIndex((pair) => pair[0] === id1 && pair[1] === id2);
      if (i < 0) {
        nextLinks.push([id1, id2]);
      } else {
        nextLinks.splice(i, 1);
      }
      console.log('nextLinks', [...nextLinks]);
      this.updateDesionData();
    },
    // eslint-disable-next-line no-unused-vars
    onFReplyUpdate(i, action) {
      // TODO this.failback.reply.splice(i, 1, action);
      this.updateFActionsHeight();
    },
    // eslint-disable-next-line no-unused-vars
    onFReplyDelete(i) {
      // TODO this.failback.reply.splice(i, 1);
      this.updateFActionsHeight();
    },
    // eslint-disable-next-line no-unused-vars
    onFMReplyUpdate(i, action) {
      // TODO this.failbackMReply.splice(i, 1, action);
      this.updateFMActionsHeight();
    },
    // eslint-disable-next-line no-unused-vars
    onFMReplyDelete(i) {
      // TODO this.failbackMReply.splice(i, 1);
      this.updateFMActionsHeight();
    },
    /**
     * 在對象分支中添加空白的 action
     * @param {Branch} 對象分支
     */
    async addReplyAction(branchId, actionInfo) {
      if (branchId) {
        // #update_reply
        const repAction = createAction(actionInfo.group, actionInfo.type);
        repAction.branchId = branchId;
        await this.$store.dispatch('rep_action/createRepAction', repAction);
        if (branchId === this.failback.id) {
          this.updateFActionsHeight();
        } else if (branchId === this.failbackM.id) {
          this.updateFMActionsHeight();
        }
      }
    },
    btnCreateBranch() {
      const branch = createBasicBranch();
      branch.desionId = this.desionId;
      branch.data = {
        t: 'b',
        sentences: [],
        next: [],
        comment: '',
      };
      // #update_desion
      this.$store.dispatch('branch/createBranch', branch);
    },
    /**
     * 從陣列中移除特定的資料
     */
    arrDel(arr, data, replace) {
      const i = arr.findIndex((item) => item === data);
      if (i >= 0) {
        if (replace) {
          arr.splice(i, 1, replace);
        } else {
          arr.splice(i, 1);
        }
      }
    },
    refreshBranchNext(branch) {
      const nSet = new Set();
      for (const nid of branch.nextLinks) {
        const { to } = this.nextNMap.get(nid);
        nSet.add(to);
      }
      console.log('refresh-branch-next:', branch, nSet);
      branch.next = nSet;
    },
  },
};
</script>

<style lang="scss" scoped>
.desion-closest-s{
  .branch-collapse-btn{
    display: flex;
    &:hover{
      opacity: .8;
    }
    &:active{
      opacity: .6;
    }
  }
  .branch-title{
    font-weight: bold;
    display: flex;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .input-branch-title{
    outline: none;
    width: 100%;
    overflow: hidden;
  }
  .title-placeholder{
    width: 24px;
  }
  .branch-area{
    &:not(:last-child){
      margin-bottom: 16px;
    }
  }
  .branch-header{
    display: flex;
    justify-content: space-between;
    padding: 6px;
    border-radius: 4px;
    cursor: pointer;
    height: 40px;
    &:hover{
      opacity: .8;
    }
    &:active{
      opacity: .6;
    }
  }
  .branch-item{
    display: flex;
    align-items: center;
    padding: 4px;
    margin-left: 3%;
    height: 40px;
    border-radius: 4px;
    width: 97%;
    cursor: pointer;
    .branch-en-number{
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFC700;
      padding: 2px;
      border: 1px solid #FFC700;
      min-width: 30px;
      margin-right: 8px;
    }
    &:hover{
      opacity: .8;
    }
    &:active{
      opacity: .6;
    }
    &.branch-add{
      width: min-content;
    }
  }
  .next-area{
    width: 100%;
  }
  .next-header{
    display: flex;
    justify-content: space-between;
    padding: 6px;
    border-radius: 4px;
    cursor: pointer;
    height: 40px;
    border: 1px solid #32BEA6;
    background-color: #EAFFFA;
    color: #32BEA6;
    .next-append-btn{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #32BEA6;
      outline: none;
    }
    &:hover{
      opacity: .8;
    }
    &:active{
      opacity: .6;
    }
  }
  .next-item{
    display: flex;
    border: 1px solid #32BEA6;
    background-color: #EAFFFA;
    color: #979797;
    align-items: center;
    padding: 4px;
    height: 40px;
    border-radius: 4px;
    width: 97%;
    .next-icon{
      display: flex;
    }
    .next-remove-btn{
      display: flex;
      &:hover{
        opacity: .8;
      }
      &:active{
        opacity: .6;
      }
    }
    .branch-remove-btn{
      &:hover{
        opacity: .8;
      }
      &:active{
        opacity: .6;
      }
    }
    input{
      display: flex;
      align-items: center;
      flex-grow: 1;
      width: 50%;
      outline: none;
      color: #616060;
      background-color: #FFFFFF;
      border: 1px solid #32BEA6;
      padding-left: 4px;
      padding-right: 4px;
      letter-spacing: 1px;
    }
  }
  .g-main{
    .branch-header{
      border: 1px solid #FFA800;
      background-color: #FFF6D8;
      color: #FFA800;
    }
    .branch-item{
      border: 1px solid #FFA800;
      background-color: white;
      color: #898989;
    }
  }
  .g-failback{
    .branch-item{
      border: 1px solid #898989;
      background-color: white;
      color: #5F5F5F;
    }
    .branch-header{
      border: 1px solid #898989;
      background-color: #EFEFEF;
      color: #5F5F5F;
    }
  }
  .branch-plus-icon{
    display: inline-flex;
    border-radius: 2.33px;
    outline: none;
    border: 1px solid #517EFE;
    background: #FFF9;
    margin-right: 4px;
  }
  .btn-branch-add{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2.33px;
    outline: none;
    border: 1px solid #517EFE;
    background: #FFF9;
    border-color: #FFC700;
  }
  .failback-actions{
    padding-left: 3%;
  }
}
svg{
  user-select: none;
  .link-point{
    cursor: pointer;
    &:hover{
      opacity: .8;
    }
    &:active{
      opacity: .6;
    }
  }
  .branch-link-point{
    fill: #FFA800;
  }
  .failback-link-point{
    fill: #898989;
  }
  .next-link-point{
    fill: #32BEA6;
  }
  .link-line{
    stroke: #DADADA;
    stroke-width: 5px;
  }
  /* 點擊後的顏色 */
  .point-selected{
    stroke: #888;
    stroke-width: 3px;
    &.branch-link-point{
      fill: #ff5e00;
    }
    &.failback-link-point{
      fill: #333;
    }
    &.next-link-point{
      fill: #28a5df;
    }
  }
}
</style>
