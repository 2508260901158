/* eslint-disable prefer-const */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
/**
 * @typedef {import('vuex').Module} VuexModule
 */

import {
  createNseDataHandleMutations,
  createNseEventHandler,
  createNseCloneAction,
  createNseBasicActions,
} from '../helpers/store-tools';
import * as nseApi from '../helpers/nse-api';

/**
 * @type {VuexModule}
 */
export const m = {
  namespaced: true,
  state: () => ({
    // 存所有的 action
    actions: [],
    idMap: {},
    // group by parent
    groupByParent: {},
  }),
  mutations: {
    // 建立操作 pre_action 的相關 mutations
    ...createNseDataHandleMutations({
      name: 'pre_action',
      stateKey: 'actions',
      getId: (data) => data.id,
      getParentId: (data) => data.plotId,
    }),
  },
  actions: {
    ...createNseEventHandler({
      createFuncName: 'createPreAction',
      updateFuncName: 'updatePreAction',
      deleteFuncName: 'deletePreAction',
    }),
    ...createNseBasicActions({
      name: 'pre_action',
      createFunc: 'createPreAction',
      updateFunc: 'updatePreAction',
      deleteFunc: 'deletePreAction',

      handleOrderBy: 'plotId',
      handleName: true,
    }),
    clonePreActions: createNseCloneAction({
      name: 'pre_action',
      parentKey: 'plotId',
      cloneKey: 'createPreAction',
      cloneChildren: [],
    }),
  },
};

export default m;
