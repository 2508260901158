/**
 * @typedef {import('vuex').Module} VuexModule
 */

import * as nseApi from '../helpers/nse-api';
import { actionDefs } from '../helpers/action-settings';
import { desionDefs } from '../helpers/desion-settings';

/**
 * @type {VuexModule}
 */
export const m = {
  namespaced: true,
  state: () => ({
    /**
     * 當前所有可新增的 action
     */
    availableActions: [],
    /**
     * 當前所有可新增的 desion
     */
    availableDesions: [],
    /**
     * publish 後會收到的提示訊息
     */
    publishInfo: [],
    /**
     * 上次發布的故事ID
     * (避免換頁之後打開 PublishDialog 還出現已發布訊息用)
     */
    lastPublishStoryId: '',
    /**
     * 當前編輯的故事的ID
     */
    storyId: null,
    /**
     * story tags
     */
    storyTags: [],
  }),
  mutations: {
    SET_STORY_ID(state, storyId) {
      state.storyId = storyId;
    },
    SET_STORY_TAGS(state, tags) {
      state.storyTags = tags;
    },
    SET_PUBLISH_INFO(state, publishInfo) {
      state.publishInfo = publishInfo;
    },
    SET_LAST_PUBLISH_STORY_ID(state, storyId) {
      state.lastPublishStoryId = storyId;
    },
    SETUP_AVAILABLE_ACTIONS(state, list) {
      if (Array.isArray(list)) {
        state.availableActions = list;
      }
    },
    SETUP_AVAILABLE_DESIONS(state, list) {
      if (Array.isArray(list)) {
        state.availableDesions = list;
      }
    },
  },
  actions: {
    /**
     * 要根據當前帳號的相關資訊(accInfo)
     * 以及當前劇本的類型
     * 過濾出所有可用的 action 和 desion
     */
    updateStorySettings({ rootState, state, commit }) {
      const { tags: accTags } = rootState.accInfo;
      const { storyTags } = state;
      const tagsSet = new Set(accTags);
      for (const tag of storyTags) {
        tagsSet.add(tag);
      }
      const aActions = [];
      for (const action of actionDefs) {
        if (tagsSet.has(action.group)) {
          aActions.push(action);
        }
      }
      commit('SETUP_AVAILABLE_ACTIONS', aActions);
      const aDesions = [];
      for (const desion of desionDefs) {
        if (tagsSet.has(desion.group)) {
          aDesions.push(desion);
        }
      }
      commit('SETUP_AVAILABLE_DESIONS', aDesions);
    },

    async loadAllDatas({ commit, dispatch }, storyId) {
      const datas = await nseApi.getStoryDatas(storyId);
      /**
       * 目前 tag 先給預設值
       * TODO 之後要針對 storyId 去查資料庫然後再設定 tags
       */
      const storyTags = ['common', 'pa-news'];
      commit('SET_STORY_ID', storyId);
      commit('SET_STORY_TAGS', storyTags);
      commit('scene/SETUP', datas.scenes, { root: true });
      commit('plot/SETUP', datas.plots, { root: true });
      commit('desion/SETUP', datas.desions, { root: true });
      commit('branch/SETUP', datas.branches, { root: true });
      commit('pre_action/SETUP', datas.preActions, { root: true });
      commit('rep_action/SETUP', datas.replyActions, { root: true });
      await dispatch('updateStorySettings');
      return datas;
    },
    async publishStory({ state, commit }, storyId) {
      if (!storyId) {
        // eslint-disable-next-line no-param-reassign
        storyId = state.storyId;
      }
      const { info } = await nseApi.publishStory(storyId);
      commit('SET_LAST_PUBLISH_STORY_ID', storyId);
      commit('SET_PUBLISH_INFO', info);
      return info;
    },
  },
};

export default m;
