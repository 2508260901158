/* eslint-disable no-param-reassign */
/**
 * @typedef {import('vuex').Module} VuexModule
 */

import {
  createNseDataHandleMutations,
  createNseEventHandler,
  createNseCloneAction,
  createNseBasicActions,
  handleDataOrder,
} from '../helpers/store-tools';
import { filterItemName } from '../helpers/utils';
import * as nseApi from '../helpers/nse-api';

/**
 * @type {VuexModule}
 */
export const m = {
  namespaced: true,
  state: () => ({
    // 存所有的 branch
    branches: [],
    idMap: {},
    // group by parent
    groupByParent: {},
  }),
  mutations: {
    // 建立操作 branches 的相關 mutations
    ...createNseDataHandleMutations({
      name: 'branch',
      stateKey: 'branches',
      getId: (data) => data.id,
      getParentId: (data) => data.desionId,
    }),
  },
  actions: {
    ...createNseEventHandler({
      createFuncName: 'createBranch',
      updateFuncName: 'updateBranch',
      deleteFuncName: 'deleteBranch',
    }),
    ...createNseBasicActions({
      name: 'branch',
      createFunc: 'createBranch',
      updateFunc: 'updateBranch',
      updateDataFunc: 'updateBranchData',
      deleteFunc: 'deleteBranch',

      handleOrderBy: 'desionId',
      handleName: true,
    }),
    cloneBranches: createNseCloneAction({
      name: 'branch',
      parentKey: 'desionId',
      cloneKey: 'createBranch',
      cloneChildren: ['rep_action/cloneRepActions'],
    }),
  },
};

export default m;
