<template>
  <div :is="layout" id="app">
    <router-view/>
  </div>
</template>
<script>
import $v from './helpers/event-bus';
import * as login from './helpers/login';
import resizeMixin from './mixins/resize';
import * as layouts from './layouts';

export default {
  mixins: [
    resizeMixin,
  ],
  components: {
    ...layouts,
  },
  computed: {
    layout() {
      console.log('app.layout:', this.$store.state.layout);
      return this.$store.state.layout;
    },
  },
  created() {
    login.initPageLoginStatus(async () => {
      console.log('on login');
      await this.$store.dispatch('updateAccountInfo');
    });
    const onTokenExpire = () => {
      const token = login.getStorageToken();
      console.log('onTokenExpire');
      if (token) {
        login.clearStorageToken();
        this.$store.commit('CLEAR_ACCOUNT_INFO');
        this.$store.commit('SET_AUTH_ERROR', {
          code: 'USER_AFK',
          message: '閒置過久，請重新登入',
          loginBtnText: '重新登入',
        });
      }
    };
    this.$onTokenExpire = onTokenExpire;
    $v.$on('token-expire', onTokenExpire);
    // this.$store.commit('SET_LOADING', true);
  },
  beforeDestroy() {
    login.onDestoryPage();
    $v.$off('token-expire', this.$onTokenExpire);
  },
};
</script>

<style lang="scss">
html,body{
  margin: 0;
  padding: 0;
}
#app{
  .ns-layout{
    font-style: normal;
    font-family: 'Roboto', 'Noto Sans TC', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: bold;
  }
  .v-application{
    font-style: normal;
    font-family: 'Roboto', 'Noto Sans TC', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: bold;
  }
}
</style>
