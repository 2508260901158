/* eslint-disable no-use-before-define */
/* eslint-disable prefer-const */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import Vue from 'vue';
import {
  empty, insertSortedList, filterItemName, searchSortedList,
} from './utils';

/**
 * @typedef {Object} createNseDataHandleMutationsOptions
 * @property {String} name log 識別用
 * @property {String} stateKey items 會保存在 state[stateKey] 之中
 * @property {Function} getId 針對 item 用的，要回傳數字ID
 * @property {Function?} getParentId 針對 item 用的，要回傳父物件的數字ID
 */

/**
 * 建立一系列操作 nse 相關資料的 mutation
 *
 * 針對 plot, desion, branch, actions...
 *
 *  使用的 store module 的條件
 *  1. state 要包含 idMap & groupByParent
 *  2. state.idMap 要是空物件
 *  3. 如果有指定 getParentId 則 state.groupByParent 必須要是空物件
 * @param {createNseDataHandleMutationsOptions} opts
 * @returns {Object.<string, function>}
 */
export function createNseDataHandleMutations(opts = {}) {
  /*
   */
  const { name, stateKey } = opts;
  const getId = (data) => {
    if (data) {
      return opts.getId(data);
    }
    return null;
  };
  if (!getId) {
    throw new Error('缺少必要參數 getId');
  }
  /** @type {function} */
  let getParentId = null;
  if (opts.getParentId) {
    getParentId = (data) => {
      if (data) {
        return opts.getParentId(data);
      }
      return null;
    };
  }
  // 如果有指定 opts.sortByOrder
  // 則優先以 order 排序，若無則照 id 排序
  // 若建立時間太接近，有可能 order 會相同，因此若相同則會依照 id 排序
  // 最終順序是看兩個屬性，所以就算 order 重複也不會有什麼問題
  const cpByOrder = (a, b) => {
    let c;
    if (a.order >= 0 && b.order >= 0) {
      c = a.order - b.order;
      if (c !== 0) {
        return c;
      }
    }
    return a.id - b.id;
  };
  const cpById = (a, b) => a.id - b.id;
  // 有指定 getParentId 才要加入 groupByParent
  let checkData = empty;
  if (getParentId) {
    checkData = (data) => {
      const id = getId(data);
      const pid = getParentId(data);
      if (!id) {
        throw new Error(`store/${name} 操作失敗，缺少 id`);
      }
      if (!pid) {
        throw new Error(`store/${name} 操作失敗，缺少 pid`);
      }
      return {
        id,
        pid,
      };
    };
  } else {
    checkData = (data) => {
      const id = getId(data);
      if (!id) {
        throw new Error(`store/${name} 操作失敗，缺少 id`);
      }
      return {
        id,
      };
    };
  }

  const checkIdUnique = (state, id) => {
    const { idMap } = state;
    if (id in idMap) {
      throw new Error(`${name} id 重複, id=${id}`);
    }
  };
  let addToGroup = empty;
  if (getParentId) {
    // 加到列表最後
    addToGroup = (state, data, pid) => {
      const { groupByParent } = state;
      let group = groupByParent[pid];
      if (!group) {
        group = [];
        Vue.set(groupByParent, pid, group);
      }
      const last = group[group.length - 1];
      if (last) {
        data.order = last.order + 1;
      } else {
        data.order = 0;
      }
      return insertSortedList(group, data, cpByOrder);
    };
  }
  let insertToGroup = empty;
  if (getParentId) {
    insertToGroup = (state, data, pid, i) => {
      const { groupByParent } = state;
      const group = groupByParent[pid];
      const len = group.length;
      // 如果原本是空的就直接加入
      if (len === 0) {
        data.order = 0;
        group.push(data);
        return 0;
      }
      if (i >= len) {
        i = len - 1;
      }
      if (i < 0) {
        i = len - i;
      }
      // order 表示同個 group 的各物件之間的相對順序
      let o;
      if (group[i + 1]) {
        o = (group[i].order + group[i + 1]) / 2;
      } else {
        o += group[i].order;
      }
      data.order = o;
      return insertSortedList(group, data, cpByOrder);
    };
  }
  // 讓資料按照正確的順序加入 list
  function addData(state, data, id) {
    const { [stateKey]: items, idMap } = state;
    Vue.set(idMap, id, data);
    return insertSortedList(items, data, cpById);
  }

  const mutations = {
    SETUP(state, datas) {
      Vue.set(state, 'idMap', {});
      Vue.set(state, 'groupByParent', {});
      const { idMap, groupByParent } = state;
      if (!datas) {
        return;
      }
      // 過濾 null 之類的
      datas = datas.filter((d) => d);
      datas.sort(cpById);
      for (const data of datas) {
        const id = getId(data);
        if (typeof id === 'number') {
          Vue.set(idMap, getId(data), data);
        }
        if (getParentId) {
          const pid = getParentId(data);
          if (typeof pid === 'number') {
            if (!groupByParent[pid]) {
              Vue.set(groupByParent, pid, []);
            }
            groupByParent[pid].push(data);
          }
        }
      }
      if (getParentId) {
        // eslint-disable-next-line guard-for-in
        for (const pid in groupByParent) {
          const group = groupByParent[pid];
          group.sort(cpByOrder);
        }
        state[stateKey] = datas;
      }
    },
    DELETE_BY_DATA(state, data) {
      if (!data) {
        return;
      }
      const { [stateKey]: items, idMap, groupByParent } = state;
      const { id, pid } = checkData(data);
      const ii = items.findIndex((item) => item === data);
      if (ii >= 0) {
        items.splice(ii, 1);
      }
      Vue.delete(idMap, id);
      if (pid) {
        const group = groupByParent[pid];
        const gi = group.findIndex((item) => item === data);
        if (gi >= 0) {
          group.splice(gi, 1);
        }
      }
    },
    DELETE_BY_ID(state, id) {
      const { idMap } = state;
      mutations.DELETE_BY_DATA(state, idMap[id]);
    },
    DELETE_BY_INDEX(state, i) {
      const { [stateKey]: items } = state;
      mutations.DELETE_BY_DATA(state, items[i]);
    },
    UPDATE(state, data) {
      if (!data) {
        return;
      }
      const { [stateKey]: items, idMap, groupByParent } = state;
      const { id, pid } = checkData(data);
      if (idMap[id]) {
        // update 不參考外部來的 order
        // const { order } = idMap[id];
        // data.order = order;
        Vue.set(idMap, id, data);
        const i = items.findIndex((d) => d.id === data.id);
        items.splice(i, 1, data);
        const gi = groupByParent[pid].findIndex((d) => d.id === data.id);
        groupByParent[pid].splice(gi, 1, data);
      } else {
        mutations.APPEND(state, data);
      }
    },
    APPEND(state, data) {
      console.log(`${name}/APPEND`);
      if (!data) {
        return;
      }
      const { id, pid } = checkData(data);
      checkIdUnique(state, id);
      addData(state, data, id);
      addToGroup(state, data, pid);
    },
    APPEND_ALL(state, list) {
      if (!list) {
        return;
      }
      for (const data of list) {
        mutations.APPEND(state, data);
      }
    },
    INSERT_TO_GROUP(state, { data, i }) {
      if (!data || !Number.isInteger(i)) {
        return;
      }
      const { id, pid } = checkData(data);
      checkIdUnique(state, id);
      insertToGroup(state, data, pid, i);
    },
    UPDATE_ORDER(state) {
      const { [stateKey]: items } = state;
      for (let i = 0; items.length; i += 1) {
        items[i].order = i;
      }
    },
  };
  return mutations;
}

/**
 * @typedef {Object} createNseEventHandlerOptions
 * @property {String} name log 識別用
 * @property {String} createFuncName
 * @property {String} updateFuncName
 * @property {String} deleteFuncName
 */

/**
 * @param {createNseEventHandlerOptions} opts
 */
export function createNseEventHandler(opts = {}) {
  let { createCB, updateCB, deleteCB } = opts;
  createCB = createCB || empty;
  updateCB = updateCB || empty;
  deleteCB = deleteCB || empty;
  const storeActions = {
    async eventCreate({ state, commit }, payload) {
      if (!state.idMap[payload.id]) {
        await createCB(payload);
        commit('APPEND', payload);
      }
    },
    async eventUpdate({ commit }, payload) {
      await updateCB(payload);
      // console.log('commit_pdate');
      return commit('UPDATE', payload);
    },
    async eventDelete({ commit }, payload) {
      await deleteCB(payload);
      // console.log('commit_Delete');
      return commit('DELETE_BY_ID', payload);
    },
  };
  return storeActions;
}

export function createNseCloneAction(opts = {}) {
  let {
    name,
    parentKey,
    cloneKey,
    cloneChildren,
    // callback, 產生新的 item 之後，在 item 上傳前呼叫
    beforeUpload,
    // callback, 產生新的 item 之後，在 item 上傳後呼叫
    afterUpload,
    // callback, 生成完所有 child 之後呼叫
    afterChildrenUpload,
  } = opts;
  cloneChildren = cloneChildren || [];
  beforeUpload = beforeUpload || empty;
  afterUpload = afterUpload || empty;
  afterChildrenUpload = afterChildrenUpload || empty;
  /**
   * 所有 clone 的方法中
   * parentId 和 id 表示用於表示來源
   * to 用於指定要複製到哪裡
   * 若要插入至特定位置，須提供 order
   *
   * @param {*} ctx
   * @param {*} payload
   */
  const func = async (ctx, payload) => {
    const { state, dispatch } = ctx;
    const {
      parentId,
      id,
      to,
      // posAfter 代表複製完的資料要放在某個ID的元素之後
      // 僅能用 ID 指定
      posAfter,

      // child 是自動填入的，外部呼叫時不要用
      child,
    } = payload;
    console.log(`run deep clone(${name})`);
    // 判斷有無指定要放置的位子，未指定將會自動放到同個 group 的最後
    // let specifyOrder = null;
    let newItems = [];
    let copyList = [];
    // if (posAfter) {
    //   let findFunc = (item) => item.id === posAfter;
    //   let list = state.groupByParent[parentId] || [];
    //   let foundItem = list.findIndex(findFunc);
    //   if(foundItem){

    //   }
    //   // specifyOrder =
    // }
    if (parentId) {
      copyList = (state.groupByParent[parentId] || []).map((item) => item.id);
    }
    if (id) {
      copyList.push(id);
    }
    for (const itemId of copyList) {
      const item = state.idMap[itemId];
      if (!item) {
        // 不能複製的跳過
        console.warn(`W:${name} ${itemId} not found`);
        newItems.push(null);
        continue;
      }
      let nItemOpt = {
        ...item,
        // to = 新 item 的 parentID
        [parentKey]: to,
      };
      nItemOpt.copyFrom = itemId;
      delete nItemOpt.id;
      delete nItemOpt.order;
      // child 表示該次的複製是基於父元素的深度複製所產生
      // 例如複製幕的話，在 plot 階段 child 會是 true，
      // desion 和 pre_action 階段就會變 false
      if (!child) {
        nItemOpt.name = `(C)${item.name}`;
      }
      await beforeUpload.call(ctx, item, nItemOpt);
      const nItem = await dispatch(cloneKey, nItemOpt);
      nItem.copyFrom = itemId;
      await afterUpload.call(ctx, item, nItem);
      newItems.push(nItem);
      // --- 複製子元素 ---
      let clonePayload = {
        parentId: itemId,
        to: nItem.id,
        // 複製子元素時 child 自動為 true
        child: true,
      };
      let storeOpts = { root: true };
      let childsMap = {};
      for (let subPath of cloneChildren) {
        childsMap[subPath] = await dispatch(subPath, clonePayload, storeOpts);
      }
      await afterChildrenUpload.call(ctx, item, nItem, childsMap);
    }
    return newItems;
  };
  return func;
}

/**
 * @typedef {Object} createNseBasicActionsOptions
 * @property {String} createFunc 建立時使用的 action 名稱，會和 nseApi 中的 function 名相同
 * @property {String} updateFunc 更新時使用的 action 名稱，會和 nseApi 中的 function 名相同
 * @property {String?} updateDataFunc 更新資料時使用的 action 名稱，會在其執行時呼叫 updateFunc 指定的 function
 * @property {String} deleteFunc 移除時使用的 action 名稱，會和 nseApi 中的 function 名相同
 * @property {String?} handleOrderBy 若有指定
 * @property {Boolean} handleName 表示是否應該處理 item 的名稱
 */

/**
 *
 * @param {createNseBasicActionsOptions} opts
 */
export function createNseBasicActions(opts = {}) {
  // eslint-disable-next-line global-require
  const nseApi = require('./nse-api');

  let {
    name,
    createFunc,
    updateFunc,
    updateDataFunc,
    deleteFunc,

    handleOrderBy,
    handleName,
  } = opts;
  const actions = {
    async [createFunc]({ state, commit }, item) {
      if (handleOrderBy) {
        handleDataOrder(state.groupByParent, item, handleOrderBy);
      }
      if (handleName) {
        filterItemName(item);
      }
      const nItem = await nseApi[createFunc](item);
      commit('APPEND', nItem);
      return nItem;
    },
    async [updateFunc]({ state, commit }, item) {
      let oItem = state.idMap[item.id];
      if (!oItem) {
        throw new Error(`${name} ${item.id} not found`);
      }
      if (handleName) {
        filterItemName(item);
      }
      try {
        let mergedItem = { ...oItem, ...item };
        commit('UPDATE', mergedItem);
        const nItem = await nseApi[updateFunc](item);
        return nItem;
      } catch (e) {
        // 還原資料
        commit('UPDATE', oItem);
        throw e;
      }
    },

    async [deleteFunc]({ commit }, itemId) {
      await nseApi[deleteFunc](itemId);
      commit('DELETE_BY_ID', itemId);
    },
  };
  if (updateDataFunc) {
    actions[updateDataFunc] = async ({ state, commit }, { id, data }) => {
      let item = state.idMap[id];
      if (!item) {
        throw new Error(`${name} ${item.id} not found`);
      }
      let oData = item.data;
      item.data = data;
      try {
        commit('UPDATE', item);
        const nItem = await nseApi[updateFunc]({ id, data });
        return nItem;
      } catch (e) {
        // 還原資料
        item.data = oData;
        commit('UPDATE', item);
        throw e;
      }
    };
  }
  return actions;
}

/**
 * 根據 groupByParent 判斷下一個 order 應該放多少
 *
 * @see {@link handleDataOrder}
 *
 * @param {*} groupByParent
 * @param {Number} parentId
 * @returns
 */
export function getLastOrderNum(groupByParent, parentId) {
  const group = groupByParent[parentId] || [];
  if (group.length === 0) {
    return 0;
  }
  return group[group.length - 1].order + 1;
}

/**
 * 根據 groupByParent 判斷插入時物件的 order 應該設多少
 *
 * @see {@link handleDataOrder}
 *
 * @param {Object.<string, Array>} groupByParent
 * @param {Number} prevId
 * @param {Number} parentId
 * @returns {Number}
 */
export function getOrderNum(groupByParent, prevId, parentId) {
  const group = groupByParent[parentId] || [];
  const i = group.findIndex((item) => item.id === prevId);
  if (i < 0) return 0;
  const prev = groupByParent[i];
  const next = groupByParent[i + 1];
  if (next) {
    return (prev.order + next.order) / 2;
  }
  return prev.order + 1;
}

/**
 * 自動處理物件的 order
 *
 * @param {Object.<string, Array>} groupByParent
 * @param {*} data
 * @param {*} parentKey
 * @param {*} prevId 若要插入資料到指定位置，要指定前者的 id，表示當前資料要放到對方後面
 * @returns {Number}
 */
export function handleDataOrder(groupByParent, data, parentKey, prevId = null) {
  let od = data.order;
  if (typeof od !== 'number' || od < 0) {
    if (prevId) {
      data.order = getOrderNum(groupByParent, prevId, data[parentKey]);
    } else {
      data.order = getLastOrderNum(groupByParent, data[parentKey]);
    }
    od = data.order;
  }
  return od;
}
