<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="600"
    v-model="show"
  >
    <div class="story-edit-dialog" v-if="story">
      <div class="dialog-header">
        故事設定 - {{ story.storyId }}
      </div>
      <div class="dialog-main">
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { debounce, keyBy } from 'lodash';
import { createAction } from '../helpers/data-create';
import { delay } from '../helpers/utils';

export default {
  props: {
    value: {
      type: Boolean,
    },
    story: {
      type: Object,
    },
  },
  computed: {
    show: {
      set(v) {
        this.$emit('input', v);
        if (this.value !== v) {
          this.$emit('change', v);
        }
      },
      get() {
        return this.value;
      },
    },
  },
  data() {
    return {
      loading: false,
      err: false,
      message: '',
    };
  },
  mounted() {
  },
  methods: {
    close() {
      this.$emit('input', false);
      this.$emit('change', false);
    },
  },
};
</script>

<style scoped lang="scss">
.story-create-dialog{
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: white;
  gap: 16px;
  .dialog-header{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    cursor: pointer;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #FFA800;
    background-color: #FFF6D8;
    color: #FFA800;
    &:hover{
      opacity: .8;
    }
    &:active{
      opacity: .6;
    }
  }
  .dialog-main{
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 16px;
  }
}
</style>
