<template>
  <div class="action-panews-change-img" :style="outterStyle">
    <div class="d-flex top-area">
      <v-spacer></v-spacer>
      <button
        v-if="!lock"
        class="btn-delete"
        :style="deleteBtnStyle"
        @click="btnDelete"
      >
        &times;
      </button>
    </div>
    <div class="action-props">
      <div class="field-title" :style="titleStyle">
        背景圖檔
      </div>
      <div class="prop-field">
        <input
          type="text"
          :placeholder="defaultPlaceholder"
          v-model="tmpBackground"
          @change="updateBackground"
          :disabled="lock"
        >
      </div>
      <div class="field-append">

      </div>
    </div>
    <v-divider></v-divider>
    <div class="action-props">
      <div class="field-title" :style="titleStyle">
        物品圖檔
      </div>
      <div class="prop-field">
        <input
          type="text"
          :placeholder="defaultPlaceholder"
          v-model="tmpForeground"
          @change="updateForeground"
          :disabled="lock"
        >
      </div>
      <div class="field-append">

      </div>
    </div>
    <template v-if="!hasCharacters">
      <v-divider></v-divider>
      <div
        class="action-props"
      >
        <v-spacer></v-spacer>
        <button
          class="f-btn btn-append"
          @click="addCharacter"
        >
          <v-icon color="#517EFE">
            mdi-plus
          </v-icon>
        </button>
        <v-spacer></v-spacer>
      </div>
    </template>
    <template v-for="character, i in tmpCharacters">
      <v-divider :key="'d' + i"></v-divider>
      <div class="action-props" :key="i">
        <div class="field-title" :style="titleStyle">
          角色圖檔
        </div>
        <div class="prop-field">
          <input
            type="text"
            :placeholder="defaultPlaceholder"
            v-model="tmpCharacters[i]"
            @change="updateCharacters"
            :disabled="lock"
          >
        </div>
        <div class="field-append">
          <button
            class="f-btn btn-append"
            :style="commonBtnStyle"
            v-if="!lock && i === tmpCharacters.length - 1"
            @click="addCharacter"
          >
            <v-icon :color="color.primary">
              mdi-plus
            </v-icon>
          </button>
          <button
            class="f-btn btn-remove-char"
            :style="commonBtnStyle"
            v-if="!lock && i !== tmpCharacters.length - 1"
            @click="removeCharacter(i)"
          >
            <v-icon :color="color.primary">
              mdi-close
            </v-icon>
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { debounce } from 'lodash';

export default {
  components: {
  },
  props: {
    action: {
      type: Object,
    },
    parent: {
      type: Object,
    },
    color: {
      type: Object,
    },
    lock: {
      type: Boolean,
    },
  },
  created() {
    // this.update = debounce(this.update, 200);
  },
  data() {
    return {
      defaultPlaceholder: '(輸入URL)',
      tmpBackground: '',
      tmpForeground: '',
      tmpCharacters: [],
    };
  },
  computed: {
    outterStyle() {
      return {
        borderColor: this.color.primary,
        backgroundColor: this.color.bgColor,
      };
    },
    titleStyle() {
      return {
        color: this.color.primary,
      };
    },
    commonBtnStyle() {
      return {
        borderColor: this.color.primary,
      };
    },
    deleteBtnStyle() {
      return {
        backgroundColor: this.color.primary,
      };
    },
    bg() {
      if (this.action) {
        return this.action.background;
      }
      return '';
    },
    characters() {
      const { action } = this;
      if (action && action.data) {
        return action.data.characters || [];
      }
      return [];
    },
    hasCharacters() {
      return this.characters && this.characters.length > 0;
    },
  },
  watch: {
    action() {
      // console.log('watch', action);
      this.initAction();
    },
    characters() {
      this.tmpCharacters = Array.from(this.characters);
    },
  },
  mounted() {
    this.initAction();
  },
  methods: {
    initAction() {
      let { data } = this.action;
      data = data || {};
      this.tmpBackground = data.background;
      this.tmpForeground = data.foreground;
      this.tmpCharacters = Array.from(data.characters || []);
    },
    removeCharacter(i) {
      const characters = this.tmpCharacters;
      characters.splice(i, 1);
      this.update({
        characters,
      });
    },
    addCharacter() {
      const characters = this.tmpCharacters;
      characters.push('');
      this.update({
        characters,
      });
    },
    updateBackground() {
      this.update({
        background: this.tmpBackground,
      });
    },
    updateForeground() {
      this.update({
        foreground: this.tmpForeground,
      });
    },
    updateCharacters() {
      this.update({
        characters: this.tmpCharacters,
      });
    },
    // 儲存
    update(patch) {
      const { action } = this;
      let { data } = action;
      data = data || {};
      const nData = {
        background: patch.background == null ? data.background : patch.background,
        foreground: patch.foreground == null ? data.foreground : patch.foreground,
        characters: patch.characters || data.characters || [],
      };
      // console.log('emit', nData);
      this.$emit('update-data', nData);
    },
    // 刪除
    btnDelete() {
      // console.log('btnDelete');
      this.$emit('delete');
    },
  },
};
</script>

<style scoped lang="scss">
$icon-size: 60px;
$border-width: 2px;
.w-100 {
  width: 100%;
}
.action-panews-change-img {
  // box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: #000000;
  height: auto;
  width: 100%;
  background-color: #F0F8FF;
  border: $border-width solid #517EFE;
  border-radius: $icon-size / 2;
  padding: 16px;
  .top-area{
    position: relative;
    top: -19px;
    right: -13px;
    height: 0px;
  }
  .btn-delete{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    width: 16px;
    height: 16px;
    color: #FFF;
    background-color: #517EFE;
    &:hover{
      opacity: 0.7;
    }
  }
  .action-props{
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: nowrap;
  }
  .field-title{
    width: 64px;
    margin-right: 10px;
    font-size: 16px;
    font-weight: bold;
    color: #517EFE;
  }
  .prop-field{
    width: auto;
    flex-grow: 1;

    input{
      width: 100%;
      padding: 4px 8px 4px 8px;
      outline: none;
      background-color: #FFF;
      border: 1px solid #ACA7A7;
      border-radius: 6.33px;
    }
    input::placeholder{
      color: rgba(150, 150, 150, 0.5);
    }
  }
  .field-append{
    width: 20px;
    margin-left: 5px;
  }
  .f-btn{
    display: inline-flex;
    border-radius: 2.33px;
    outline: none;
    &:hover{
      opacity: .8;
    }
    &:active{
      opacity: .6;
    }
  }
  .btn-append{
    border: 1px solid #517EFE;
    background: #FFF9;
  }
}
</style>
