<template>
  <div class="ns-layout">
    <v-app>
      <v-app-bar
        app
        color="white"
      >
        <div class="nse-app-bar">
          <div>
            <VoissEditorBrand to="/editor" />
          </div>
          <template v-if="paAccount">
            <div>
              <img
                height="35px"
                src="../assets/pa-logo.png"
                alt="親子天下 LOGO"
              />
            </div>
          </template>
          <template v-if="!paAccount">
            <div></div>
          </template>
          <div>
          </div>
        </div>
      </v-app-bar>

      <v-main class="ns-page">
        <slot v-if="showView && !authError"/>
        <RequireLogin v-else />
      </v-main>
      <StoryPublishDialog
        v-model="showDialog"
      />
      <LoaderDialog v-model="loading" :message="loadMsg"/>
    </v-app>
  </div>
</template>

<script>
import RequireLogin from '../components/RequireLogin.vue';
import VoissEditorBrand from '../components/VoissEditorBrand.vue';
import StoryPublishDialog from '../components/StoryPublishDialog.vue';
import LoaderDialog from '../components/LoaderDialog.vue';
import $v from '../helpers/event-bus';

export default {
  components: {
    RequireLogin,
    VoissEditorBrand,
    StoryPublishDialog,
    LoaderDialog,
  },
  computed: {
    loading: {
      set(v) {
        this.$store.commit('SET_LOADING', v);
      },
      get() {
        return this.$store.state.loading;
      },
    },
    loadMsg() {
      return this.$store.state.loadMsg;
    },
    showPublish() {
      const { state } = this.$store;
      return state.accInfo && state.story.storyId;
    },
    authError() {
      const { state } = this.$store;
      const { authError } = state;
      return authError;
    },
    /**
     * 表示要顯示"要求登入的畫面"還是 router-view
     */
    showView() {
      const { state } = this.$store;
      const { requireLogin, accInfo } = state;
      if (!requireLogin) {
        return true;
      }
      return !!accInfo;
    },
    paAccount() {
      const { state } = this.$store;
      return state.paAccount;
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  methods: {
    async btnShowDialog() {
      this.showDialog = true;
      $v.$emit('layout/show-publish-dialog');
    },
  },
};
</script>

<style lang="scss" scoped>
.nse-app-bar{
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  &>div{
    display: flex;
    align-items: center;
    width: 33%;
    &:nth-child(2){
      justify-content: center;
    }
    &:nth-child(3){
      justify-content: right;
    }
  }
}
</style>
