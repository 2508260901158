<template>
  <div class="editor-page">
    <div>
      <table class="story-table">
        <thead class="story-table-header">
          <tr>
            <th
              class="story-table-title"
              v-for="title,i in headers"
              :key="i"
            >
              {{title}}
            </th>
          </tr>
        </thead>
        <tbody class="story-table-body">
          <tr v-if="stories.length === 0">
            <td :colspan="headers.length">
              當前還沒有任何故事喔
            </td>
          </tr>
          <tr
            class="story-row"
            v-for="story in stories"
            :key="story.storyId"
          >
            <td>{{story.name}}</td>
            <td>{{story.storyId}}</td>
            <td>
              <v-btn color="primary" :to="`/editor/${story.storyId}`">
                開啟
              </v-btn>
            </td>
            <td class="text-center">
              <v-btn class="mr-2" dark color="#FFA800" @click="showPublishDialog(story.storyId)">
                發佈
              </v-btn>
              <v-btn color="secondary" @click="showEditDialog(story)" disabled>
                設定
              </v-btn>
            </td>
          </tr>
          <tr class="story-row" >
            <td :colspan="headers.length" class="text-center">
              <v-btn color="primary" @click="showCreateDialog()">
                新建
              </v-btn>
            </td>
          </tr>
        </tbody>
      </table>
      <StoryPublishDialog
        v-model="pubDialog.show"
        :story-id="pubDialog.storyId"
      />
      <StoryEditDialog
        v-model="editDialog.show"
        :story="editDialog.story"
      />
      <StorySimpleCreateDialog
        v-model="createDialog.show"
        :story="createDialog.story"
        @create-story="init()"
      />
    </div>
  </div>
</template>

<script>
import StoryEditDialog from '../components/StoryEditDialog.vue';
import StoryPublishDialog from '../components/StoryPublishDialog.vue';
import StorySimpleCreateDialog from '../components/StorySimpleCreateDialog.vue';
import * as nseApi from '../helpers/nse-api';

export default {
  name: 'StoryManage',
  layout: 'normal',
  // layout 會根據 requireLogin 做出反應
  // 當 requireLogin 為 true 且用戶尚未登入時
  // layout 將不會顯示當前頁面內容，而是要求用戶登入
  requireLogin: true,
  components: {
    StoryEditDialog,
    StoryPublishDialog,
    StorySimpleCreateDialog,
  },
  computed: {
    accInfo() {
      return this.$store.state.accInfo;
    },
    storyId() {
      return this.$store.state.story.storyId;
    },
  },
  watch: {
    accInfo: {
      handler() {
        // 切換帳號 or 登入後要重新載入並檢查
        this.init();
      },
      immediate: true,
    },
  },
  data() {
    return {
      headers: [
        '故事名稱',
        '故事ID',
        '故事連結',
        '操作',
      ],
      stories: [],
      pubDialog: {
        show: false,
        storyId: '',
      },
      editDialog: {
        show: false,
        story: null,
      },
      createDialog: {
        show: false,
        story: null,
      },
    };
  },
  methods: {
    async init() {
      const stories = await nseApi.getOwnedStories();
      this.stories = stories;
    },
    showPublishDialog(storyId) {
      this.pubDialog.show = true;
      this.pubDialog.storyId = storyId;
    },
    showEditDialog(story) {
      this.pubDialog.show = true;
      this.pubDialog.story = story;
    },
    showCreateDialog() {
      this.createDialog.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.editor-page{
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  color: #888;
  .story-table{
    border-collapse: collapse;
    tr{
      padding: 16px 24px 16px 24px;
      border-bottom: 1.5px solid rgba(0, 0, 0, 0.3);
    }
    td, th{
      padding: 16px 24px 16px 24px;
    }
  }
  // .story-row td, .story-table-title{
  //   padding: 16px 24px 16px 24px;
  //   border-top: 1px solid #000;
  // }
}
</style>
