/* eslint-disable no-await-in-loop */
import Vue from 'vue';
import Vuex from 'vuex';

import { getSelfInfo } from '../helpers/nse-api';
import { getSettionId } from '../helpers/nse-settings';

import story from './story';
import scene from './scene';
import plot from './plot';
import branch from './branch';
import desion from './desion';
import preAction from './pre_action';
import repAction from './rep_action';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    story,
    scene,
    plot,
    branch,
    desion,
    pre_action: preAction,
    rep_action: repAction,
  },
  state: {
    title: '',
    // 表示當前的 view 要採用的 layout
    // (會在 router 切換時自動更新 (依據 routerView.layout)
    layout: 'empty',
    // 表示當前的 view 必須要登入才可用
    // (會在 router 切換時自動更新 (依據 routerView.requireLogin)
    requireLogin: false,
    // 在登入後如果頁面因為帳號權限或其他問題不能正常運作
    // 且要要求用戶重新登入時，可用該欄位設定錯誤訊息
    //
    // showLoginBtn: 是否要顯示重新登入的按鈕(預設 true)
    // eg. { message: '當前帳號缺少對應權限', showLoginBtn: false }
    authError: null,
    accInfo: null,
    /**
     * 判斷是不是親子天下的帳號
     */
    paAccount: false,
    loading: false,
    loadMsg: '',
    // App.vue 中會自動更新該值 (表示畫面的 size)
    screen: { width: 0, height: 0 },
  },
  mutations: {
    SET_TITLE(state, title) {
      state.title = title;
    },
    SET_ACCOUNT_INFO(state, accInfo) {
      state.paAccount = accInfo && accInfo.tags.find((tag) => tag === 'pa-news');
      state.accInfo = accInfo;
    },
    CLEAR_ACCOUNT_INFO(state) {
      state.paAccount = false;
      state.accInfo = null;
    },
    SET_LAYOUT(state, layout) {
      state.layout = layout;
    },
    SET_REQUIRE_LOGIN(state, requireLogin) {
      state.requireLogin = requireLogin;
    },
    SET_LOADING(state, loading) {
      state.loading = !!loading;
      // 關閉時要清掉內容
      if (!state.loading) {
        state.loadMsg = '';
      }
    },
    SET_LOAD_MSG(state, msg) {
      state.loadMsg = msg;
    },
    SET_AUTH_ERROR(state, authError) {
      const { code, message, data } = authError;
      let { showLoginBtn, loginBtnText } = authError;
      // loginBtnText 為登入按鈕上要寫的字，若未指定則由 RequireLogin，自行顯示
      loginBtnText = loginBtnText || '';
      // showLoginBtn 預設為 true
      if (showLoginBtn == null) {
        showLoginBtn = true;
      }
      state.authError = {
        code,
        message,
        data,
        showLoginBtn,
        loginBtnText,
      };
    },
    SET_AUTH_ERROR_MESSAGE(state, message) {
      state.authError = {
        code: '',
        message,
        data: {},
        showLoginBtn: true,
      };
    },
    CLEAR_AUTH_ERROR(state) {
      state.authError = null;
    },
    SET_SCREEN(state, screen) {
      state.screen = screen;
    },
  },
  actions: {
    /**
     * 更新畫面的大小
     * @param {*} ctx
     * @param {*} data
     */
    updateScreen({ commit }, data) {
      // eslint-disable-next-line no-param-reassign
      data = data || {};
      const height = data.height || window.innerHeight;
      const width = data.width || window.innerWidth;
      commit('SET_SCREEN', { width, height });
    },
    /**
     * 自動呼叫 API 更新用戶資料
     * @param {*} ctx
     */
    async updateAccountInfo({ commit, dispatch }) {
      const accInfo = await getSelfInfo();
      accInfo.tags = [
        'common',
        'pa-news',
      ];
      // console.log('c', accInfo);
      // TODO 之後可能要補上避免重複呼叫的機制
      // TODO 在此要透過 api 取得 tags
      commit('SET_ACCOUNT_INFO', accInfo);
      await dispatch('story/updateStorySettings', accInfo);
    },

    async handleStoryEvents({ dispatch }, events) {
      // eslint-disable-next-line no-param-reassign
      events = events || [];
      const mySessionId = getSettionId();
      for (const event of events) {
        // console.log(mySessionId === event.sessionId);
        if (event.sessionId !== mySessionId) {
          const [action, kPath] = event.eventPath.split(':');
          switch (action) {
            case 'create':
              await dispatch(`${kPath}/eventCreate`, event.data);
              break;
            case 'update':
              await dispatch(`${kPath}/eventUpdate`, event.data);
              break;
            case 'delete':
              await dispatch(`${kPath}/eventDelete`, event.data);
              break;
            default:
              console.log('unknown event', event);
          }
          console.log('process', event);
        } else {
          console.log('self events');
        }
      }
    },
  },
});
