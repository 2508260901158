/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable no-use-before-define */
/* eslint-disable import/prefer-default-export */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-const */
import axios from './nse-axios';

/*
  nse-event.js，同步畫面資訊用
 */

/**
 * api 驗證用的 token
 * (登入後透過 setToken 設定)
 */

let timeout = 20000;

/**
 * 同步頁面資料用，自動重複執行取得編輯器事件
 *
 * 若要停止可執行 ctx.stopLoop
 *
 * @param {String} storyId
 * @param {*} onEvents
 * @returns 回傳一個循環查詢的 context
 */
export function queryEvents(storyId, onEvents) {
  let ctx = {
    t: 0,
    storyId,
    stop: false,
    stopLoop() {
      ctx.stop = true;
    },
    startId: 0,
  };
  return _queryEvents(ctx, onEvents);
}

function _queryEvents(ctx, onEvents) {
  const {
    storyId,
    startId,
  } = ctx;
  ctx.t += 1;
  const postData = {
    timeout: timeout * 0.8,
    startId,
  };
  axios.post(`/nse/story_events/${storyId}`, postData, { timeout })
    .then((res) => {
      if (!ctx.stop) {
        ctx.startId = res.data.startId;
        onEvents(ctx, res.data);
        runNextQuery(0);
      }
    })
    .catch(() => {
      if (!ctx.stop) {
        // 失敗的話等久一點再 run
        runNextQuery(5000);
      }
    });

  function runNextQuery(t) {
    setTimeout(() => {
      if (!ctx.stop) {
        _queryEvents(ctx, onEvents);
      }
    }, t);
  }
  return ctx;
}
